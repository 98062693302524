
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, ViewChild, NgZone, HostListener } from "@angular/core";
import { ToastService } from "../../common/providers/toast.service";
import { takeUntil, take, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ColorControlDialogComponent } from "../color-control-dialog/color-control-dialog.component";
import { CommonRepository } from "../repositories/common-repository";
import { MailTag } from "../models/mail-tag.model";
import { Message } from "../shared/models";
import { MailConstants } from "src/app/common/utils/mail-constants";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { MailRootState } from "../store";
import { getTagById } from "../store/selectors";
import { Conversation } from "../shared/models";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { forkJoin } from "rxjs";
import { MailService } from "../shared/services/mail-service";
import { UpdateConversationSuccess } from "../store/actions/conversation.action";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { MailUtils } from "../utils/mail-utils";
import { BriefcaseRepository } from "src/app/briefcase/repositories/briefcase.repository";
import { BriefcaseService } from "src/app/briefcase/services/briefcase.service";
import { BriefcaseRootState } from "src/app/briefcase/store/selectors";
import { UpdateManyBriefcaseFileSuccess } from "src/app/briefcase/store/actions";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CommonService } from "src/app/services/ common.service.";
import { isArray } from "util";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfigService } from "src/app/config.service";
import { AppService } from "src/app/services/app.service";
import { FormControl } from "@angular/forms";

const COLOR_LIST = [MailConstants.DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];
@Component({
  selector: "vp-create-tag",
  templateUrl: "./create-tag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreateTagComponent implements OnDestroy {
  maxTagNameLength: number = 128;
  isRename: boolean = false;
  isConversation: boolean = false;
  tagName: string = "";
  oldTagName: string = "";
  tags: MailTag[] = [];
  tagColor: string = MailConstants.DEFAULT_TAG_COLOR;
  message: Message;
  convMessages: Message[] = [];
  conversation: Conversation;
  @ViewChild("tagNameInput", {static: false}) tagNameInput: ElementRef<HTMLInputElement>;
  private isAlive$ = new Subject<boolean>();
  assignedTags: MailTag[] = [];
  prevAssignedTags: MailTag[] = [];
  isMultiple: boolean = false;
  selectedIds: any[] = [];
  convIds: any [] = [];
  msgIds: any [] = [];
  moduleType: string = "mail";
  briefcaseFiles: any = [];
  calendarEvent: any;
  searchTag: string = "";
  selectedIndex: number = 1;
  showGlobalTags: boolean = false;
  copyAssignedTagForMultiple:any = []
  tagControl: FormControl = new FormControl("");
  tagSearchControl: FormControl = new FormControl("");


  constructor(
    private dialogRef: MatDialogRef<CreateTagComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private mailBroadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private convRepository: ConversationRepository,
    private ngZone: NgZone,
    private store: Store<MailRootState>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private mailService: MailService,
    private briefcaseRepository: BriefcaseRepository,
    private briefcaseService: BriefcaseService,
    private briefcaseStore: Store<BriefcaseRootState>,
    private calendarRepository: CalendarRepository,
    private commonService: CommonService,
    private configService: ConfigService,
  ) {
    this.onResize();
    this.tagSearchControl.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.searchTag = value;
    });

    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    if (this.data.moduleType) {
      this.moduleType = this.data.moduleType;
    }
    this.isRename = this.data.isRename;
    this.isMultiple = this.data.isMultiple;
    if (this.moduleType === "mail") {
      this.setMailModuleData();
    } else if (this.moduleType === "briefcase") {
      this.setBriefcaseModuleData();
    } else if (this.moduleType === "calendar") {
      this.setCalendarData();
    }
    if (this.isRename) {
      this.tagName = this.data.tag.name;
      this.oldTagName = this.data.tag.name;
      if (this.data.tag.rgb) {
        this.tagColor = this.data.tag.rgb.toLowerCase().trim();
        this.changeDetectionRef.markForCheck();
      }
    }

    this.commonRepository.getTagsListWithParam(this.showGlobalTags).pipe(takeUntil(this.isAlive$))
      .subscribe(tags => {
        this.tags = tags.map(tag => {
          if (tag.color && COLOR_LIST[tag.color]) {
            tag.color = COLOR_LIST[tag.color];
          }
          if (tag.rgb) {
            tag.rgb = tag.rgb.toLowerCase();
            if (tag.rgb === "#999") {
              tag.rgb = "#999999";
            }
          }
          return tag;
        });
        this.changeDetectionRef.markForCheck();
      });
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_TAG_CREATE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    setTimeout(() => {
      if (this.tagNameInput) {
        this.tagNameInput.nativeElement.focus();
      }
    }, 200);
  }

  private setAssignedTags(ids) {
    this.assignedTags = this.getTags(ids);
    this.copyAssignedTagForMultiple = this.getTags(ids);
    this.assignedTags.forEach(tag => {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim());
      if (tagIndex !== -1) {
        this.tags.splice(tagIndex, 1);
      }
    });
  }

  closeDialog(): void {
    this.close();
  }

  updateDefaultColor(event) {
    this.tagColor = event.value;
    this.changeDetectionRef.markForCheck();
  }

  isValidTagName(): boolean {
    if ((this.tagControl.value.length > 0) && (this.tagControl.value.indexOf("\"") === -1)) {
      return true;
    } else {
      return false;
    }
  }

  isValidTagNameNgmodule(): boolean {
    if ((this.tagName.length > 0) && (this.tagName.indexOf("\"") === -1)) {
      return true;
    } else {
      return false;
    }
  }


  changeText(event) {
    if (this.tagName.length > this.maxTagNameLength) {
      this.tagName = this.tagName.substring(0, this.maxTagNameLength);
      this.changeDetectionRef.markForCheck();
    }
  }

  addTag() {
    if (this.tagControl.value.length < 1) {
      return;
    }
    if (!this.isValidTagName()) {
      this.toastService.show("INVALID_TAG_ERROR_MSG");
      return;
    }
    this.tagControl.patchValue(this.tagControl.value.trim());
    const isInTags = this.tags.filter(tag => this.tagControl.value.toLowerCase().trim() === tag.name.toLowerCase().trim()).length > 0;
    const isInAssignedTags = this.assignedTags.filter(tag => this.tagControl.value.toLowerCase().trim() === tag.name.toLowerCase().trim()).length > 0;
    if (isInTags || isInAssignedTags) {
      this.toastService.show("DUPLICATE_TAG_MSG");
      return;
    } else {
      if (this.configService.useVNCdirectoryAuth) {
        this.commonRepository.createDirectoryTag(this.tagControl.value, this.tagColor).subscribe(tag => {
          console.log("created new Directory tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          setTimeout(() => {
            this.addNewTagToAssign(tag);
          }, 100);
        });
      } else {
        this.commonRepository.createTag(this.tagControl.value, this.tagColor).subscribe(tag => {
          this.tagControl.patchValue("");
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          setTimeout(() => {
            this.addTagToAssign(tag);
          }, 100);
        });
      }
    }
  }

  addDirectoryTag() {
    this.commonRepository.createDirectoryTag(this.tagName, this.tagColor).subscribe(tag => {
      console.log("[directory tag resp]::", tag);
    });
  }

  addTagToAssign(tag: any) {
    tag.name = tag.name.trim();
    const isTagAdded = this.assignedTags.filter(t => t.name.toLowerCase().trim() === tag.name.toLowerCase().trim()).length > 0;
    if (!isTagAdded) {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim());
      if (tagIndex !== -1) {
          this.tags.splice(tagIndex, 1);
      }
      this.assignedTags.push(tag);
      if (this.configService.useVNCdirectoryAuth) {
        if (!this.commonRepository.isTagAvailableInZimbra(tag.name)) {
          this.commonRepository.createTag(tag.name, tag.color).subscribe(tag => {
            console.log("[Response of tag::]", tag);
          });
        }
      }
    }
    this.changeDetectionRef.markForCheck();
  }


  saveTags() {
    console.log("[save]");
    if (this.moduleType === "mail") {
      this.saveMailTags();
    } else if (this.moduleType === "briefcase") {
      this.saveBriefcaseTags();
    } else  if (this.moduleType === "calendar") {
      this.saveCalendarTag();
    }
  }

  addTagToConversation(isMultiple: boolean, tag: MailTag, conversation?: Conversation) {
    if (isMultiple) {
      if (this.convIds.length > 0) {
        let conversations: Conversation[] = [];
        if (!!this.data.conversations) {
          conversations = this.data.conversations.filter(v => !!v);
        } else {
          this.convRepository.getConversationsByIds(this.convIds).pipe(distinctUntilChanged(), take(1)).subscribe(convs => {
            conversations = convs.filter(v => !!v);
          });
        }
        console.log("[UpdateConversationSuccess]", conversations);
        conversations.forEach(conv => {
          if (tag && conv.tags.indexOf(tag.name) === -1) {
            conv.tags.push(tag.name);
            this.convRepository.addTagToObject(conv, tag);
            const tags = conv.tags;
            const tn = conv.tn;
            if (!!conv.m) {
              conv.m.forEach(m => {
                this.convRepository.addTagToObject(m, tag);
              });
            }
            this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conv.m});
            this.store.dispatch(new UpdateConversationSuccess({id: conv.id, changes: {tags, tn, m: conv.m}}));
            if (this.convMessages) {
              this.convMessages.forEach(m => this.addTagtoMessage(m, tag));
            }
          } else if (!!conv.m && tag) {
            conv.m.forEach(m => {
              this.convRepository.addTagToObject(m, tag);
            });
          }
        });
      }
      if (this.msgIds.length > 0) {
        let messages: Message[] = [];
        if (!!this.data.messages) {
          messages = this.data.messages.filter(v => !!v);
        } else {
          this.convRepository.getMessagesByIds(this.msgIds).pipe(distinctUntilChanged(), take(1)).subscribe(msgs => {
            messages = msgs.filter(v => !!v);
          });
        }
        const convIds = [];
        messages.forEach(m => {
          if (!convIds.includes(m.cid)) {
            convIds.push(m.cid);
          }
          this.addTagtoMessage(m, tag);
        });
        this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: messages});
        console.log("[addTagtoMessage]", messages);

        this.convRepository.getConversationsByIds(convIds).pipe(take(1)).subscribe(convs => {
          if (!!convs && convs.length > 0) {
            convs.filter(v => !!v).forEach(conv => {
              conv.m.forEach(m => {
                if (this.msgIds.includes(m.id)) {
                  this.addTagtoMessage(m, tag);
                }
              });
              this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conv.m});
              console.log("[addTagToConversation]", conv.m);
            });
          } else {
            messages.forEach(m => this.addTagtoMessage(m, tag));
            this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: messages});
            console.log("[addTagToConversation]", messages);
          }
        });
      }
    } else if (!!conversation) {
      conversation.tags.push(tag.name);
      this.convRepository.addTagToObject(conversation, tag);
      const tags = conversation.tags;
      const tn = conversation.tn;
      if (!!conversation.m) {
        conversation.m.forEach(m => {
          this.convRepository.addTagToObject(m, tag);
        });
        this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conversation.m});
      }
      this.store.dispatch(new UpdateConversationSuccess({id: conversation.id, changes: {tags, tn, m: conversation.m}}));
      if (this.convMessages) {
        this.convMessages.forEach(m => this.addTagtoMessage(m, tag));
      }
    }
  }

  removeTagFromConversation(conversation: Conversation, tag: MailTag) {
    conversation = this.convRepository.removeTagFromObject(conversation, tag);

    if (this.convMessages) {
      this.convMessages.forEach(m => this.removeTagFromMessage(m, tag));
    }
  }

  addTagtoMessage(message: Message, tag: MailTag) {
    if (tag) {
      message = this.convRepository.addTagToObject(message, tag);
      this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: [message]});
    }
    if (this.data.fromDetail) {
      this.convRepository.getConversationsByIds([message.cid]).pipe(take(1)).subscribe(convs => {
        if (!!convs && convs.length > 0 && convs[0]) {
          const conv = convs[0];
          conv.m.forEach(m => {
            if (m.id === message.id) {
              this.convRepository.addTagToObject(m, tag);
            }
          });
          this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conv.m});
          this.store.dispatch(new UpdateConversationSuccess({id: conv.id, changes: {tn: conv.tn, t: conv.t, m: conv.m}}));
        }
      });
    }
    this.convRepository.updateMessages([message]);
    this.changeDetectionRef.markForCheck();
  }

  removeTagFromMessage(message: Message, tag: MailTag) {
    message = this.convRepository.removeTagFromObject(message, tag);

    if (this.data.fromDetail) {
      this.convRepository.getConversationsByIds([message.cid]).pipe(take(1)).subscribe(convs => {
        if (!!convs && convs.length > 0 && convs[0]) {
          const conv = convs[0];
          let changed = false;
          if (tag) {
            if (conv.t) {
              const tagIds = conv.t.split(",");
              if (tagIds.indexOf(tag.id) !== -1) {
                _.remove(tagIds, {id: tag.id});
                conv.t = tagIds.join(",");
                changed = true;
              }
            }
            if (conv.tn) {
              const tagIds = conv.tn.split(",");
              if (tagIds.indexOf(tag.name) !== -1) {
                _.remove(tagIds, {name: tag.name});
                conv.tn = tagIds.join(",");
              }
            }
          }
          conv.m.forEach(m => {
            if (m.id === message.id) {
              m.t = message.t;
              m.tn = message.tn;
            }
          });

          this.mailBroadcaster.broadcast(BroadcastKeys.UPDATE_TAGS, {messages: conv.m});

          if (changed) {
            this.store.dispatch(new UpdateConversationSuccess({id: conv.id, changes: {tn: conv.tn, t: conv.t, m: conv.m}}));
          }
        }
      });
    }
    this.changeDetectionRef.markForCheck();
  }


  save(): void {
    if (this.tagName.length < 1) {
      return;
    }
    this.tagName = this.tagName.trim();
    if (this.isTagAlreadyExist()) {
      console.log("DUPLICATE_TAG_MSG", this.tagName);
      this.toastService.show("DUPLICATE_TAG_MSG");
      return;
    }
    if (!this.isValidTagNameNgmodule()) {
      this.toastService.show("INVALID_TAG_ERROR_MSG");
      return;
    }
    if (this.isRename) {
      if (this.tagName.toLowerCase().trim() !== this.oldTagName.toLowerCase().trim()) {
        this.commonRepository.renameTag(this.data.tag.id, this.tagName);
      }
      const color = this.data.tag.rgb || this.data.tag.color || "";
      if (this.tagColor.toLowerCase() !== color.toLowerCase()) {
        this.commonRepository.updateTagColor(this.data.tag.id, this.tagColor);
      }
      this.close();
    } else {
      if (this.configService.useVNCdirectoryAuth) {
        this.commonRepository.createDirectoryTag(this.tagName, this.tagColor).subscribe(tag => {
          console.log("created new Directory tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          this.dialogRef.close({tag: tag});
        });
      } else {
        this.commonRepository.createTag(this.tagName, this.tagColor).subscribe(tag => {
          console.log("created new tag", tag);
          this.toastService.show(MailConstants.TAG_CREATED_LBL);
          this.dialogRef.close({tag: tag});
        });
      }
    }
  }

  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "244px",
      autoFocus: false,
      panelClass: "vp-color-control-dialog",
      data: { folderColor: this.tagColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (!!operation && operation !== null && operation.selectedColor) {
        this.tagColor = operation.selectedColor;
      }
    });
  }

  getTagColor(color) {
    const isDark = MailUtils.lightOrDark(color) === "dark";
    if (isDark) {
        return "#ffffff";
    } else {
        return "#000000";
    }
  }

  isTagAlreadyExist() {
    if (this.tagName.toLowerCase().trim() === this.oldTagName.toLowerCase().trim()) {
      return false;
    }
    return this.tags.filter(tag => this.tagName.toLowerCase().trim() === tag.name.toLowerCase().trim()).length > 0;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  close(isNull: boolean = false): void {
    if (isNull) {
      this.dialogRef.close(null);
    } else {
      this.dialogRef.close(this.assignedTags.length);
    }
  }

  getTags(ids: string): MailTag[] {
    let tags: MailTag[] = [];
    if (ids) {
      tags = ids.split(",").map(id => {
        let tag: MailTag;
        this.store.select(state => getTagById(state, id)).pipe(take(1)).subscribe(t => {
          if (!!t) {
              if (t.color && MailConstants.COLOR_LIST[t.color]) {
                  t.color = MailConstants.COLOR_LIST[t.color];
              }
              if (t.rgb) {
                  t.rgb = t.rgb.toLowerCase();
                  if (t.rgb === "#999") {
                      t.rgb = "#999999";
                  }
              }
              tag = t;
              }
          });
          return tag;
      });
      tags = _.sortBy(tags.filter(t => !!t), t => t.name.toLowerCase());
    }
    return tags;
  }

  removeTag(tag: MailTag): void {
    this.tags.push(tag);
    const tagIndex = this.assignedTags.findIndex((v) => v.id === tag.id);
    if (tagIndex !== -1) {
      this.assignedTags.splice(tagIndex, 1);
    }
  }

  setMailModuleData(): void {
    if (!!this.data && this.data.conversation) {
      this.conversation = this.data.conversation;
    }
    this.isConversation = this.data.isConversation;
    if (this.isConversation) {
      if (this.isMultiple) {
        this.selectedIds = this.data.selectedIds;
      } else {
        this.convMessages = this.data.messages;
      }
    } else {
      this.message = this.data.message;
    }
    if (this.isMultiple) {
      this.convIds = this.data.convIds;
      this.msgIds = this.data.msgIds;
    }
    if (!this.conversation && !this.message) {
      if (this.data.conversations && this.data.conversations.length > 0) {
        const firstConversation = this.data.conversations[0];
        this.setAssignedTags(firstConversation.t);
      } else if (this.data.messages && this.data.messages.length > 0) {
        const firstMessage = this.data.messages[0];
        this.setAssignedTags(firstMessage.t);
      }
    } else {
      if (!this.isConversation && this.message && this.message.t) {
        this.setAssignedTags(this.message.t);
        console.log("setAssignedTags message", this.message)

      }
      if (this.isConversation && this.conversation && this.conversation.t) {
        console.log("setAssignedTags", this.conversation)
        this.setAssignedTags(this.conversation.t);
      }
    }
    this.prevAssignedTags = this.assignedTags.slice();
    this.changeDetectionRef.markForCheck();
  }

  setBriefcaseModuleData(): void {
    this.briefcaseFiles = this.data.briefcaseFiles;
    this.setAssignedTags(this.briefcaseFiles[0].t);
    this.prevAssignedTags = this.assignedTags.slice();
    this.changeDetectionRef.markForCheck();
  }

  removeTagOneByOneFromMessage(message, tag) {
    this.convRepository.removeTagInMessage(message.id, tag.name).subscribe(res => {
      this.changeDetectionRef.markForCheck();
    });
  }
  saveMailTags(): void {
    console.log("[saveMailTags]", this.isMultiple, this.assignedTags);
    if (this.isMultiple) {
      const data2IdsSet = new Set(this.assignedTags.map(item => item.id));
      const result = this.copyAssignedTagForMultiple.filter(item => !data2IdsSet.has(item.id));
      result.map(rs=>{
        this.data.messages.map(msg=>{
            this.removeTagFromMessage(msg, rs);
            this.removeTagOneByOneFromMessage(msg, rs)
        })
      })
      const updateTags: any = [];
      this.assignedTags.forEach(tag => {
        let body;
          if (this.convIds.length > 0) {
            body = { id: this.convIds.toString(), op: "tag", tn: tag.name.trim() };
            updateTags.push(this.convRepository.conversationAction(body));
          }
          if (this.msgIds.length > 0) {
            body = { id: this.msgIds.toString(), op: "tag", tn: tag.name };
            updateTags.push(this.convRepository.messageAction(body, false, true));
            console.log("[saveMailTags]", tag.name);
          }
      });
      forkJoin(updateTags).subscribe(
        (response: any) => {
          response.forEach((res: any, index) => {
          console.log("update multiple tags data", response)

            this.addTagToConversation(true, this.assignedTags[index]);
          });
          if (navigator.onLine) {
            this.mailBroadcaster.broadcast(BroadcastKeys.PROCESS_PENDING_OPERATIONS);
          }
          this.close();
          this.changeDetectionRef.markForCheck();
        });
    } else {
      const removeTags: MailTag[] = [];
      const allTags: MailTag[] = [];
      const updateTags: any = [];
      this.prevAssignedTags.forEach( tag => {
        const tagIndex = this.assignedTags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim());
        if (tagIndex === -1) {
          let body;
          if (!this.isConversation) {
            body = { id: this.message.id, op: "!tag", tn: tag.name };
            updateTags.push(this.convRepository.messageAction(body));
          } else {
            body = { id: this.conversation.id, op: "!tag", tn: tag.name };
            updateTags.push(this.convRepository.conversationAction(body));
          }
          removeTags.push(tag);
          allTags.push(tag);
        }
      });

      let addTags: MailTag[] = [];
      addTags = this.assignedTags.filter(tag => {
        return this.prevAssignedTags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim()) === -1;
      });

      addTags.forEach(tag => {
        let body;
        if (!this.isConversation) {
          body = { id: this.message.id, op: "tag", tn: tag.name };
          updateTags.push(this.convRepository.messageAction(body));
        } else {
          body = { id: this.conversation.id, op: "tag", tn: tag.name };
          updateTags.push(this.convRepository.conversationAction(body));
        }
        allTags.push(tag);
      });
      forkJoin(updateTags).subscribe(
        (response: any) => {
          response.forEach((res: any, index) => {
            if (index < removeTags.length) {
              if (!this.isConversation) {
                this.removeTagFromMessage(this.message, allTags[index]);
              } else {
                this.removeTagFromConversation(this.conversation, allTags[index]);
              }
            } else {
              if (!this.isConversation) {
                this.addTagtoMessage(this.message, allTags[index]);
              } else {
                this.addTagToConversation(false, allTags[index], this.conversation);
              }
            }
          });
          this.changeDetectionRef.markForCheck();
          this.close();
        }
      );
    }
  }

  saveBriefcaseTags(): void {
    const removeTags: MailTag[] = [];
    const allTags: MailTag[] = [];
    const updateTags: any = [];
    const ids = this.briefcaseFiles.map(file => file.id);
    this.prevAssignedTags.forEach( tag => {
      const tagIndex = this.assignedTags.findIndex((v) => v.id === tag.id);
      if (tagIndex === -1) {
        let body;
        body = { id: ids.toString(), op: "!tag", tn: tag.name };
        updateTags.push(this.briefcaseService.itemAction(body));
        removeTags.push(tag);
        allTags.push(tag);
      }
    });
    let addTags: MailTag[] = [];
    addTags = this.assignedTags.filter(tag => {
      return this.prevAssignedTags.findIndex((v) => v.id === tag.id) === -1;
    });
    addTags.forEach(tag => {
      let body;
      body = { id: ids.toString(), op: "tag", tn: tag.name };
      updateTags.push(this.briefcaseService.itemAction(body));
      allTags.push(tag);
    });
    forkJoin(updateTags).subscribe(
      (response: any) => {
        response.forEach((res: any, index) => {
          if (res.action.op === "tag") {
            this.addTagToBriefcaseTags(allTags[index]);
          } else if (res.action.op === "!tag") {
            this.removeTagToBriefcaseTags(allTags[index]);
          }
        });
        this.changeDetectionRef.markForCheck();
        this.close();
      });
    }

  removeTagToBriefcaseTags(tag: MailTag): void {
    const ids = this.briefcaseFiles.map( file => file.id);
    let briefcaseFiles: any [] = [];
    if (this.briefcaseFiles.length > 0) {
      this.briefcaseRepository.getBriefcaseFileById(ids).pipe(distinctUntilChanged(), take(1)).subscribe(files => {
        briefcaseFiles = files.filter(v => !!v);
      });
      briefcaseFiles.forEach( file => {
        const t = file.t.split(",");
        const tn = file.tn.split(",");
        const tagIndex = t.findIndex((v) => v === tag.id);
        const tagIndex1 = tn.findIndex((v) => v === tag.name);
        if (tagIndex !== -1) {
          t.splice(tagIndex, 1);
        }
        if (tagIndex1 !== -1) {
          tn.splice(tagIndex1, 1);
        }
        file.tn = tn.toString();
        file.t = t.toString();
        this.briefcaseStore.dispatch(new UpdateManyBriefcaseFileSuccess([file]));
      });
    }
  }

  addTagToBriefcaseTags (tag: MailTag): void {
    const ids = this.briefcaseFiles.map( file => file.id);
    let briefcaseFiles: any [] = [];
    if (this.briefcaseFiles.length > 0) {
      this.briefcaseRepository.getBriefcaseFileById(ids).pipe(distinctUntilChanged(), take(1)).subscribe(files => {
        briefcaseFiles = files.filter(v => !!v);
      });
      briefcaseFiles.forEach( file => {
        if (tag && file?.tn?.indexOf(tag.name) === -1) {
          let tn: any [] = [];
          if (file.tn) {
            tn = file.tn.split(",");
            tn.push(tag.name);
          }
          file.tn = file.tn ? tn.join(",") : tag.name;
          file.t = file.t ? file.t + "," + tag.id : tag.id;
          this.briefcaseStore.dispatch(new UpdateManyBriefcaseFileSuccess([file]));
        }
      });
    }
  }

  setCalendarData(): void {
    this.calendarEvent = this.data.calendarEvent;
    if (!isArray(this.calendarEvent)) {
      this.setAssignedTags(this.calendarEvent.t);
    }
    this.prevAssignedTags = this.assignedTags.slice();
    this.changeDetectionRef.markForCheck();
  }

  saveCalendarTag(): void {
    const removeTags: MailTag[] = [];
    const allTags: MailTag[] = [];
    const updateTags: any = [];
    const ids = isArray(this.calendarEvent) ? this.calendarEvent.map(ev => ev.apptId) : this.calendarEvent.apptId;
    this.prevAssignedTags.forEach( tag => {
      const tagIndex = this.assignedTags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim());
      if (tagIndex === -1) {
        let body;
        body = { id: ids.toString(), op: "!tag", tn: tag.name };
        updateTags.push(this.commonService.itemAction(body));
        removeTags.push(tag);
        allTags.push(tag);
      }
    });
    let addTags: MailTag[] = [];
    addTags = this.assignedTags.filter(tag => {
      return this.prevAssignedTags.findIndex((v) => v.id === tag.id) === -1;
    });
    addTags.forEach(tag => {
      let body;
      body = { id: ids.toString(), op: "tag", tn: tag.name };
      updateTags.push(this.commonService.itemAction(body));
      allTags.push(tag);
    });
    forkJoin(updateTags).subscribe(
      (response: any) => {
        response.forEach((res: any, index) => {
        });
        this.changeDetectionRef.markForCheck();
        this.dialogRef.close({ close: true});
      });
  }

  addNewTagToAssign(tag: any) {
    tag.name = tag.name.trim();
    const isTagAdded = this.assignedTags.filter(t => t.name.toLowerCase().trim() === tag.name.toLowerCase().trim()).length > 0;
    if (!isTagAdded) {
      const tagIndex = this.tags.findIndex((v) => v.name.toLowerCase().trim() === tag.name.toLowerCase().trim());
      if (tagIndex !== -1) {
          this.tags.splice(tagIndex, 1);
      }
      this.assignedTags.push(tag);
    }
    this.changeDetectionRef.markForCheck();
  }

  toggleShowGlobalTags(val) {
    this.commonRepository.getTagsListWithParam(this.showGlobalTags).pipe(takeUntil(this.isAlive$)).subscribe(tags => {
      this.tags = tags.map(tag => {
        if (tag.color && COLOR_LIST[tag.color]) {
          tag.color = COLOR_LIST[tag.color];
        }
        if (tag.rgb) {
          tag.rgb = tag.rgb.toLowerCase();
          if (tag.rgb === "#999") {
            tag.rgb = "#999999";
          }
        }
        return tag;
      });
      this.changeDetectionRef.markForCheck();
    });
  }


  onMouseOver(tag): void {
    let tagEl = document.getElementById("tag-selected-"+ tag.id);
    const backgroundColor = window.getComputedStyle(tagEl, null).getPropertyValue('background-color');
    const textColor = window.getComputedStyle(tagEl, null).getPropertyValue('color');
    tagEl.style.backgroundColor = textColor;
    tagEl.style.color = backgroundColor;
    tagEl.style.outline = "solid 1px " + backgroundColor;
  }

  onMouseOut(tag): void {
    let tagEl = document.getElementById("tag-selected-"+ tag.id);
    const backgroundColor = window.getComputedStyle(tagEl, null).getPropertyValue('background-color');
    const textColor = window.getComputedStyle(tagEl, null).getPropertyValue('color');
    tagEl.style.backgroundColor = textColor;
    tagEl.style.color = backgroundColor;
  }

  clientWidth: any;
  @HostListener("window:resize", ["$event"])
  onResize() {
    console.log("window:resize event", window.innerWidth);
    this.clientWidth = window.innerWidth;
  }

}
