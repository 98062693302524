
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, NgZone, AfterViewInit } from "@angular/core";
import { UserProfile } from "../shared/models";
import {
  getUserProfile,
  getSearchKeyword,
  getAllSearch,
  getAvailableZimlets,
  getZimbraFeatures,
  getDomainSpecificLogo,
  getSearchFor,
  getIncludeSharedItems,
  getOnlineStatus,
  getIsRightSidebarExpanded,
  getAllUserContacts,
  getExpandMailFolders
} from "../reducers";
import { AuthService } from "../common/providers/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigService } from "../config.service";
import { Store, select } from "@ngrx/store";
import { Observable, of, Subject } from "rxjs";
import { takeUntil, filter, take, debounceTime, distinctUntilChanged, switchMap, map} from "rxjs/operators";
import { CommonUtils } from "../common/utils/common-util";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { MailUtils } from "../mail/utils/mail-utils";
import { SearchState } from "../reducers/search";
import { environment } from "../../environments/environment";
import { HeaderService } from "../services/header.service";
import { SearchItem } from "../shared/models/search-item";
import { AppState } from "../reducers/app";
import { SetSearchFor, SetIncludeShared, SetActiveProfile, SetRightSidebarStatus, SetExpandMailFolders } from "../actions/app";
import { MailBroadcaster } from "../common/providers/mail-broadcaster.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ShortcutDialogComponent } from "../mail/shared/components/shortcut-dialog/shortcut-dialog.component";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { GeneralSettingsDialogComponent } from "../mail/shared/components/general-settings-dialog/general-settings-dialog.component";
import { ChangelogDialogComponent } from "../mail/shared/components/changelog-dialog/changelog-dialog.component";
import { AboutDialogComponent } from "../mail/shared/components/about-dialog/about-dialog.component";
import { ServicedeskDialogComponent } from "../mail/shared/components/servicedesk-dialog/servicedesk-dialog.component";
import { HelpFaqDialogComponent } from "../mail/shared/components/help-faq-dialog/help-faq-dialog.component";
import { MobileAboutDialogComponent } from "../mail/shared/components/mobile-about-dialog/mobile-about-dialog.component";
import { MailRootState } from "../mail/store";
import { SearchFolder } from "../shared/models/search-folder";
import { AppsDialogComponent } from "../shared/components/apps-dialog/apps-dialog.component";
import { MobileTagsComponent } from "../mail/shared/components/mobile-tags/mobile-tags.component";
import { RefineSearchDialogComponent } from "../mail/shared/components/refine-search/refine-search-dialog";
import { LegalNoticeDialogComponent } from "../mail/shared/components/legal-notice-dialog/legal-notice-dialog.component";
import { BroadcastKeys } from "../common/enums/broadcast.enum";
import { isArray } from "util";
import { ElectronService } from "../services/electron.service";
import { ToastService } from "../common/providers/toast.service";
import { MailFolder } from "../mail/models/mail-folder.model";
import { DesktopChangePasswordComponent } from "../mail/shared/components/desktop-change-password/desktop-change-password.component";
import { MailService } from "../mail/shared/services/mail-service";
import { PreferenceService } from "../preference/shared/services/preference.service";
import { AppService } from "../services/app.service";
import { ZimbraFeatures } from "../common/utils/zimbra-features";
import { TranslateService } from "@ngx-translate/core";
import { FormatFileSizePipe } from "../shared/pipes/format-file-size.pipe";
import { AdvancedSearchDialogComponent } from "../shared/components/advanced-search-dialog/advanced-search-dialog.component";
import { MobileChangePasswordComponent } from "../mail/shared/components/mobile-change-password/mobile-change-password.component";
import { PreferenceState } from "../preference/store/reducers";
import { SubFolderComponent } from "../mail/shared/components/mobile-folders/sub-folders.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { AppearanceDialogComponent } from "../shared/components/appearance-dialog/appearance-dialog.component";
import { DatabaseService } from "../services/db/database.service";
import { CommonRepository } from "../mail/repositories/common-repository";
import { ProfileDetailDialogComponent } from "src/app/shared/components/profile-detail-dialog/profile-detail-dialog.component";
import { TopBarService, TopBarComponent, GlobalSearchComponent, GlobalSearchService, VncLibraryService } from "vnc-library";
import { ResponsiveService } from "src/app/common/providers/responsive.service";
import { CalendarView } from "../calendar/vp-calendar/vp-calendar.module";
import * as _ from "lodash";
import { getMailFolders } from "../mail/store/selectors";
import { SetSelectedMailFolder } from "../mail/store/actions";
import { CommonService } from "../services/ common.service.";
import { DisplayTimePipe } from "../shared/pipes/displayTime.pipe";
import { highlightByQuery } from "js-solr-highlighter";
import { ConversationRepository } from "../mail/repositories/conversation.repository";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  currentUser: UserProfile;
  searchKeywordItem: SearchItem[];
  historyKeywordItem: SearchItem[];
  searchKeyword: string;
  searchMode = false;
  private isAlive$ = new Subject<boolean>();
  showUserMenu: boolean = false;
  profileBackground = "url(" + CommonUtils.getFullUrl(environment.profileBackgroundImage) + ")";
  emailOptionExpanded: boolean = false;
  isSearchQueryExist: boolean = false;
  searchText: string = "";
  siteHeaderMenu = [
    { name: "MAIL", url: "/mail", icon: "email", badge: 0 , display: true , isSiteHeader: true},
    { name: "TASKS", url: "/task", icon: "event_available", badge: 0 , display: false , isSiteHeader: false},
    { name: "CALENDAR", url: "/calendar", icon: "date_range", badge: 0 , display: true , isSiteHeader: true},
    { name: "PREFERENCES_LBL", url: "/preferences/general", icon: "settings_applications", badge: 0 , display: true , isSiteHeader: true}
  ];
  switchApp: boolean = false;
  currentUrl: string = "";
  searchFolders: SearchFolder[];
  isCordovaApp: boolean;
  isCordovaOrElectron: boolean;
  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  penIcon = CommonUtils.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtils.getFullUrl("/assets/img/filter.svg");
  @ViewChild(TopBarComponent) topBarComponent: TopBarComponent;
  @ViewChild(TopBarService) topBarService: TopBarService;
  @ViewChild("searchOptionsMenuTrigger") searchOptionsMenuTrigger: MatMenuTrigger;
  @ViewChild("advancedSearchMenuTrigger") advancedSearchMenuTrigger: MatMenuTrigger;

  screen: string = this.responsiveService.getScreen();

  menuIconSide = "18px";
  menuIconColor = "#8b96a0";

  isMobileScreen: boolean = false;
  appLogo: string = "";
  appLogoDesktop: string = "";
  textLogo: string = "";
  srcLogo: string = "";
  emailTemplateFolder: MailFolder;
  brandName = environment.mobileTextLogo;
  private searchKeyDown = new Subject();
  isBlueTheme: boolean = false;
  isGreenTheme: boolean = false;
  isEmailFormsZimletEnabled = false;
  isPreferenceEnabled: boolean = true;
  isSharingFeatureEnabled: boolean = true;
  isMailFilterFeatureEnabled: boolean = true;
  isOutOfOfficeFeatureEnabled: boolean = true;
  avatarURL: string;
  hideServiceDeskMenu: boolean = false;
  hideAppsMenu: boolean = false;
  hideFAQMenu: boolean = false;
  hideLegalNoticeMenu: boolean = false;
  hideImprintMenuItem: boolean = true;
  serviceDeskMenuItemName: string = "VNC Service Desk";
  isContactModule: boolean;
  isBriefcaseModule: boolean;
  showAdvancedSearchPopover: any;
  isBriefcaseNotificationEnabled: boolean = false;
  domainSpecificLogoAvailable: boolean = false;
  domainSpecificUserLogo: string = "";
  isListView: boolean = false;
  isMobileSearch: boolean = false;
  faqURL: string;
  userManual: string;
  serviceDeskURL: string;
  termsOfUse: string;
  dataPrivacy: string;
  imprintLink: string;
  searchFor = localStorage.getItem("getSearchFor") || "mail";
  includeSharedItems = (localStorage.getItem("includeSharedItems") || "true") === "true";
  isHandset: boolean;
  displayHeaderAvatar: any;
  quotaMsg: string = "";
  quotaPercent: string = "0"
  usedQuote: number;
  isSafari = MailUtils.isSafari();
  isDFBTheme: boolean = false;
  isHandsetLandscape = false;
  hideProfile: boolean = false;
  zimbraFeatureChangePasswordEnabled = true;
  hideTrustedAddressesFromSettings: boolean = false;
  browserLang: string = "en";
  themeChangeRestricted: boolean = false;
  isSecurityFeatureEnabled: boolean = false;
  CalendarView = CalendarView;
  selectingView: { view: CalendarView, titleCode: string } = { view: CalendarView.Day, titleCode: "CALENDARS.DAY_VIEW_TITLE" };
  isOnline: boolean;
  isUseDirectoryAuth: boolean = false;
  isHideContactSearchSelectionOption: boolean = true;
  autoSearchResult: any[] = [];
  subscription: any;
  calendarOnly = environment.calendarOnly;
  rightSidebarExpanded: boolean;
  attachmentTitle: string;

  thirdPartyContacts: any[] = [];
  cc_raw_ss: any[] = [];
  from_raw_ss: any[] = [];
  bcc_raw_ss: any[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    public config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private configService: ConfigService,
    private store: Store<SearchState | AppState | MailRootState | PreferenceState>,
    private headerService: HeaderService,
    private matDialog: MatDialog,
    private mailBroadCaster: MailBroadcaster,
    private _hotkeysService: NgxHotkeysService,
    private electronService: ElectronService,
    private toastService: ToastService,
    private ngZone: NgZone,
    private mailService: MailService,
    private hotKeyService: NgxHotkeysService,
    private appService: AppService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private databaseService: DatabaseService,
    private vnclibraryService: VncLibraryService,
    private globalSearchService: GlobalSearchService,
    private commonRepository: CommonRepository,
    private formatFileSizePipe: FormatFileSizePipe,
    private responsiveService: ResponsiveService,
    private commonService: CommonService,
    private conversationRepo: ConversationRepository
  ) {
    console.log("[HeaderComponent] constructor", new Date());
    this.conversationRepo.anothermailSearch().subscribe(res => {
      if (res && res.facet_counts && res.facet_counts.facet_fields) {
        this.thirdPartyContacts = res.facet_counts.facet_fields;
      }
      let resultArray = Object.entries(this.thirdPartyContacts['cc_raw_ss']).map(([key, value]) => {
        let match = key.match(/^(.*?) <(.*?)>$/);
        if (match) {
          return {
            name: match[1].split('@')[0],
            email: match[2],
            count: value
          };
        } else {
          // Include entries that don't match the expected format
          return {
            name: key.split('@')[0],
            email: key,
            count: value
          };
        }
      });
      this.cc_raw_ss = resultArray;

      let from_raw_Array = Object.entries(this.thirdPartyContacts['from_raw_s']).map(([key, value]) => {
        let match = key.match(/^(.*?) <(.*?)>$/);
        if (match) {
          return {
            name: match[1].split('@')[0],
            email: match[2],
            count: value
          };
        } else {
          // Include entries that don't match the expected format
          return {
            name: key.split('@')[0],
            email: key,
            count: value
          };
        }
      });
      this.from_raw_ss = from_raw_Array;

      let bcc_raw_Array = Object.entries(this.thirdPartyContacts['bcc_raw_ss']).map(([key, value]) => {
        let match = key.match(/^(.*?) <(.*?)>$/);
        if (match) {
          return {
            name: match[1].split('@')[0],
            email: match[2],
            count: value
          };
        } else {
          // Include entries that don't match the expected format
          return {
            name: key.split('@')[0],
            email: key,
            count: value
          };
        }
      });
      this.bcc_raw_ss = bcc_raw_Array;

    });
    this.isCordovaApp = environment.isCordova;
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!environment.isCordova) {
      this.registerShortcuts();
    }
    if (environment.calendarOnly) {
      this.siteHeaderMenu = [
        { name: "CALENDAR", url: "/calendar", icon: "date_range", badge: 0 , display: true, isSiteHeader: true},
        { name: "PREFERENCES_LBL", url: "/preferences/general", icon: "settings_applications", badge: 0 , display: true, isSiteHeader: true }
      ];
    }
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.isHandset = this.breakpointObserver.isMatched([Breakpoints.Handset]);
    this.isHandsetLandscape = this.breakpointObserver.isMatched([Breakpoints.HandsetLandscape]);
    this.mailBroadCaster.on<any>(MailConstants.IS_LIST_VIEW).pipe(takeUntil(this.isAlive$))
    .subscribe(value => {
      if (this.isListView !== value && this.isHandset) {
        this.isListView = value;
        this.changeDetectorRef.markForCheck();
      }
    });

    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
        this.isHandsetLandscape = this.breakpointObserver.isMatched([Breakpoints.HandsetLandscape]);
        this.changeDetectorRef.markForCheck();
      });
    this.mailBroadCaster.on<any>(MailConstants.UPDATE_SEARCH_HEIGHT).pipe(takeUntil(this.isAlive$))
    .subscribe(res => {
      this.isMobileSearch = !!res.isMobileSearch;
      this.changeDetectorRef.markForCheck();
    });
    this.setLocale();
    if (environment.theme === "dfb") {
      this.themeChangeRestricted = true;
      this.changeDetectorRef.markForCheck();
    }

    this.store.pipe(select(getOnlineStatus), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.isOnline = isOnline;
    });

    this.mailBroadCaster.on<any>(MailConstants.SEARCH_ADVANCE_SEARCH).pipe(takeUntil(this.isAlive$))
    .subscribe(value => {
      setTimeout(() => {
        this.search();
        this.changeDetectorRef.markForCheck();
      }, 500);
    });

  }

  setLocale() {
    this.browserLang = this.translateService.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      this.browserLang = localLang;
    }
    this.browserLang = this.browserLang.match(/en|de/) ? this.browserLang : "en";
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    let setLogoData = "";
    console.log("environment", environment);
    if (environment.theme === MailConstants.DFB) {
      setLogoData = "<span class=\"vnc\">DFB</span><span class=\"mail\">mail</span>";
    } else {
      setLogoData = "<span class=\"vnc\">VNC</span><span class=\"mail\">mail</span>";
    }
    this.textLogo = setLogoData;
    this.changeDetectorRef.markForCheck();
    this.store.select(getIsRightSidebarExpanded).pipe(takeUntil(this.isAlive$))
    .subscribe(res => {
      this.rightSidebarExpanded = res;
      this.changeDetectorRef.markForCheck();
    });
    this.config.getAllConfig().pipe(debounceTime(2000), takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[HeaderComponent][getAllConfig]: ", res);
      if (this.config.get("hideProfile") === true) {
        this.hideProfile = true;
        this.changeDetectorRef.markForCheck();
      }
      if (this.config.get("zimbraFeatureChangePasswordEnabled") === "TRUE") {
        this.zimbraFeatureChangePasswordEnabled = true;
      } else {
        this.zimbraFeatureChangePasswordEnabled = false;
      }
      this.changeDetectorRef.markForCheck();
      if (this.hideTrustedAddressesFromSettings !== this.config.get("hideTrustedAddressesFromSettings")) {
        this.hideTrustedAddressesFromSettings = this.config.get("hideTrustedAddressesFromSettings");
        this.changeDetectorRef.markForCheck();
      }
      if (this.isSecurityFeatureEnabled !== this.config.two_factor_authentication) {
        this.isSecurityFeatureEnabled = this.config.two_factor_authentication;
        this.changeDetectorRef.markForCheck();
      }
      if (res.useVNCdirectoryAuth && res.useVNCdirectoryAuth === true) {
        this.isUseDirectoryAuth = true;
      } else {
        this.isUseDirectoryAuth = false;
      }
      this.setUrlsFromConfig();
      this.setHeaderBrandName();
      if (!this.isOnline) {
        this.isHideContactSearchSelectionOption = false;
        const index = this.siteHeaderMenu.findIndex(m => m.url === "/contacts");
        if (index !== -1) {
          this.siteHeaderMenu.splice(index, 1);
        }
        this.changeDetectorRef.markForCheck();
      } else {
        if (res.useVNCdirectoryAuth && res.useVNCdirectoryAuth === true) {
          this.isHideContactSearchSelectionOption = false;
          const index = this.siteHeaderMenu.findIndex(m => m.url === "/contacts");
          if (index !== -1) {
            this.siteHeaderMenu.splice(index, 1);
          }
          this.changeDetectorRef.markForCheck();
        } else {
          const contactindex = this.siteHeaderMenu.findIndex(m => m.url === "/contacts");
          if (contactindex < 0) {
            this.siteHeaderMenu.splice(1, 0,
              { name: "CONTACTS", url: "/contacts", icon: "person", badge: null, display: true, isSiteHeader: false }
            )
          }
        }
      }
    });
    if (this.displayHeaderAvatar !== this.config.displayHeaderAvatar) {
      this.displayHeaderAvatar = this.config.displayHeaderAvatar;
      this.changeDetectorRef.markForCheck();
    }
    if (environment.theme === MailConstants.DFB) {
      this.isDFBTheme = true;
      this.changeDetectorRef.markForCheck();
      this.setLogoAndText();
    } else {
      this.setHeaderBrandName();
      this.appLogo = environment.appLogo;
    }
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe((res: any) => {
      let tempProfile = null;
      console.log("[getUserProfile] header", res);
      if (!!res) {
        tempProfile = res;
        tempProfile.email = MailUtils.getSingleEmail(res.email);
        this.currentUser = tempProfile;
        this.changeDetectorRef.markForCheck();
      } else {
        const contactUser = localStorage.getItem("profileUser");
        if (!!contactUser) {
          this.currentUser = MailUtils.parseUserProfile(contactUser);
        }
      }
      let avatarId = "";
      if (!!this.currentUser) {
        if (environment.isElectron) {
          avatarId = this.electronService.md5(this.currentUser.email);
        } else {
          avatarId = md5(this.currentUser.email);
        }
        this.avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg`;
      }
      this.changeDetectorRef.markForCheck();
      this.getDomainSpecificUsers();
    });

    this.mailBroadCaster.on<any>("openAdvanceSearch").pipe(takeUntil(this.isAlive$))
    .subscribe(v => {
      console.log("[openAdvanceSearch]", v);
      this.openAdvanceSearch(v);

    });
    this.store.select(getSearchFor).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      console.log("[getSearchFor]", value);
      this.searchFor = !!value ? value : "mail";
      localStorage.setItem("getSearchFor", value);
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getIncludeSharedItems).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      console.log("[getIncludeSharedItems]", value);
      this.includeSharedItems = value;
      localStorage.setItem("includeSharedItems", value.toString());
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>("openMobileMenu").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.siwtchAppMenuOption();
    });
    this.mailBroadCaster.on<any>("closeAutocomplete").pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.topBarService.closeAutocomplete();
    });


    this.store.select(getSearchKeyword).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      setTimeout(() => {
      if (res) {
        const parsedQuery = MailUtils.parseSearchQuery(res);
        this.searchText = parsedQuery.searchText;
        this.isSearchQueryExist = true;
      } else {
        this.isSearchQueryExist = false;
      }
      }, 200);
      this.changeDetectorRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.isContactModule = this.currentUrl.startsWith("/contacts");
      this.isBriefcaseModule = this.currentUrl.startsWith("/briefcase");
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getAllSearch).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.searchFolders = res;
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>(MailConstants.UPDATE_UNREAD_COUNT).pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        if (this.currentUrl.lastIndexOf("mail/tag/") !== -1) {
          this.siteHeaderMenu[0]["badge"] = null;
        } else {
          this.siteHeaderMenu[0]["badge"] = data.unreadCount;
        }
        this.changeDetectorRef.markForCheck();
      });

    this.mailBroadCaster.on<any>(BroadcastKeys.HIDE_SEARCH_PANEL).pipe(takeUntil(this.isAlive$))
    .subscribe(() => {
      this.ngZone.run(() => {
        this.searchMode = false;
        this.changeDetectorRef.markForCheck();
      });
    });

    this.mailBroadCaster.on<any>(BroadcastKeys.HIDE_SIDEBAR_DRAWER).pipe(takeUntil(this.isAlive$))
    .subscribe(data => {
      this.showUserMenu = false;
      this.updateMailListScrollBroadcast(false);
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>(BroadcastKeys.OPEN_SIBAR_DRAWER).pipe(takeUntil(this.isAlive$))
    .subscribe(data => {
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>(BroadcastKeys.OPEN_PREFERENCE_TAB).pipe(takeUntil(this.isAlive$))
    .subscribe(pref_part => {
      if (pref_part && !this.isMobileScreen) {
        const prefrenceTab = this.siteHeaderMenu.find(tab => tab.name === "PREFERENCES_LBL");
        if (prefrenceTab) {
          prefrenceTab.url = "/preferences/" + pref_part;
        }
        this.changeDetectorRef.markForCheck();
      }
    });


    this.mailBroadCaster.on<any>(BroadcastKeys.EMAIL_TEMPLATE_FOLDER).pipe(takeUntil(this.isAlive$))
    .subscribe(mailFolder => {
      this.emailTemplateFolder = mailFolder?.emailTemplateFolder;
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getAvailableZimlets).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res && res.length > 0) {
        this.isEmailFormsZimletEnabled = MailUtils.isZimletEnabledOrMendatory(res, "biz_vnc_email_forms");
        this.isBriefcaseNotificationEnabled = MailUtils.isZimletEnabledOrMendatory(res, "biz_vnc_briefcase_notification");
        this.changeDetectorRef.markForCheck();
      }
    });

    this.mailBroadCaster.on<any>(MailConstants.UPDATE_THEME_DFB).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      if (environment.theme === MailConstants.DFB) {
        this.setLogoAndText();
      }
     });

    this.mailBroadCaster.on<any>("CALL_FROM_USER_LIST").pipe(takeUntil(this.isAlive$))
    .subscribe(res => {
      this.getSendASAndBehalfOf();
    });

    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {

      this.isPreferenceEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_PREFERENCE_ENABLED);
      this.isSharingFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_SHARING_ENABLED);
      this.isMailFilterFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_FILTER_ENABLED);
      this.isOutOfOfficeFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_OUT_OF_OFFICE_ENABLED);
      this.config.isZimbraBriefcaseEnabled = (!!res && res.length > 0) ? MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_BRIEFCASE_ENABLED) : false;
      const prefindex = this.siteHeaderMenu.findIndex(m => m.url === "/preferences/general");
      if (!this.config.isZimbraBriefcaseEnabled) {
        const index = this.siteHeaderMenu.findIndex(m => m.url === "/briefcase");
        if (index !== -1) {
          this.siteHeaderMenu.splice(index, 1);
        }
      } else {
        const briefcaseindex = this.siteHeaderMenu.findIndex(m => m.url === "/briefcase");
        if (briefcaseindex < 0) {
          // console.log("prefindex adding briefcase ...")
          this.siteHeaderMenu.splice(prefindex, 0,
            { name: "BRIEFCASE_TITLE", url: "/briefcase", icon: "work", badge: 0, display: true, isSiteHeader: true }
          )
        }
      }
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>("SHOW_HIDEMENU_ITEMS").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      if (this.config.showHideSettingsMenu.hideServiceDeskMenu) {
        this.hideServiceDeskMenu = this.config.showHideSettingsMenu.hideServiceDeskMenu;
      }
      if (this.config.showHideSettingsMenu.hideManualFAQ) {
        this.hideFAQMenu = this.config.showHideSettingsMenu.hideManualFAQ;
      }
      if (this.config.showHideSettingsMenu.serviceDeskHeaderText) {
        this.serviceDeskMenuItemName = this.config.showHideSettingsMenu.serviceDeskHeaderText;
      }
      if (this.config.showHideSettingsMenu.hideLegalNoticeMenu) {
        this.hideLegalNoticeMenu = this.config.showHideSettingsMenu.hideLegalNoticeMenu;
      }
      if (!this.config.showHideSettingsMenu.hideImprintMenuItem) {
        this.hideImprintMenuItem = this.config.showHideSettingsMenu.hideImprintMenuItem;
      }
      this.changeDetectorRef.markForCheck();
    });

    this.mailBroadCaster.on<any>("HIDE_APPS_MENU").pipe(takeUntil(this.isAlive$)).subscribe( res => {
      if (this.config.hideAppsMenu) {
        this.hideAppsMenu = this.config.hideAppsMenu;
      }
      this.changeDetectorRef.markForCheck();
    });

    this.config.getUsedQuota().pipe(takeUntil(this.isAlive$)).subscribe( (res: number) => {
      this.usedQuote = res;
      this.setUserQuotaInfo(res);
    });

    this.config.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe( res => {
      console.log("[Language Change]:", res , "[UserQuota] : ", this.usedQuote);
      if (!!res && res !== this.browserLang) {
        this.browserLang = res;
        this.setUrlsFromConfig();
      }
      if (!!this.usedQuote) {
        this.setUserQuotaInfo(this.usedQuote);
      }
    });

    this.searchKeyDown.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(res => {
      if (environment.theme !== MailConstants.DFB && !this.config.get("isSearchFromSolr")) {
        return;
      }
      if (this.searchKeyword === "") {
        this.autoSearchResult = [];
        this.changeDetectorRef.markForCheck();
      } else {
        this.subscription = this.mailService.solrautoSuggest(this.searchKeyword).pipe(take(1)).subscribe(res => {
          if (!!res && res.results && Array.isArray(res.results)) {
            const result = res.results;
            this.autoSearchResult = [];
            result.map(r => {
              if (r.title_s) {
                this.autoSearchResult.push(r.title_s);
              }
            });
            this.changeDetectorRef.markForCheck();
          }
        }, err => {
          this.toastService.showPlainMessage(err);
          this.autoSearchResult = [];
          this.changeDetectorRef.markForCheck();
        });
      }
    });
  }

  public getTranslatedFolder(name: string) {
    const folderName: { [key: string]: any } = {
      "INBOX": "Inbox",
      "DRAFTS": "Drafts",
      "SPAM": "Spam",
      "NOT_SPAM": "Not Spam",
      "STARRED": "Starred",
      "SENT": "Sent",
      "TRASH": "Trash"
    };
    let translationKey = "INBOX";
    Object.keys(folderName).forEach((key: string) => {
      if (folderName[key] === name) {
        translationKey = key;
      }
    });
    this.translate.get(translationKey).pipe(take(1)).subscribe(text => {
      name = text;
    });
    return name;
  }

  ngAfterViewInit(): void {
    this.topBarService.search$.pipe(
      takeUntil(this.isAlive$),
      debounceTime(500))
      .subscribe(search => {
        if (search.query.trim() !== "") {
          this.searchKeyword = search.query;
          this.search();
        }
      });

    this.translateService.get("ATTACHMENT").pipe(take(1)).subscribe((title: string) => {
      this.attachmentTitle = title;
    });

    this.topBarService.isSearchBarActive$.pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.commonService.isSearchHeaderFocused$.next(v);
      if (!!v) {
        this.appService.getSearchQueries().subscribe((res: any) => {
          let results = [];
          if (!!res && res.queries) {
            results = res.queries;
          }
          this.topBarService.setSavedSearchItems(results);
        });
      }
    });

    this.topBarService.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((v) => {
          if (!!v && v.trim().length > 0) {
            let attachmentText = "Attachments";
            this.translate.get("ATTACHMENTS_LABEL").pipe(take(1)).subscribe(v => {
              attachmentText = v;
            });
            return this.mailService.solrautoSuggest(v as string).pipe(take(1),
                map(res => {
                  if (!!res && res.results && Array.isArray(res.results)) {
                    const result = res.results;
                    return result.map(el => {
                      let item = Object.assign({}, el);

                      item.type_s = "mail";
                      item.originalTitle = item.title_s;
                      let avatarId = "";
                      if (environment.isElectron) {
                        avatarId = this.electronService.md5(item.from_s);
                      } else {
                        avatarId = md5(item.from_s);
                      }
                      const avatarURL = `${this.config.avatarURL}/${avatarId}.jpg`;
                      const content = item?.content_txt && item?.content_txt[0] ? item?.content_txt[0] : "";
                      item.jid = item.from_s;
                      const specialChars = ["\\","@","&","%","^","!",".", "*", "+", "-", "?", "|", "(", ")", "[", "]", "{", "}", "^", "$", ":", "\""];
                      let textHighlight;
                      try {
                        const pattern = new RegExp(`[${specialChars.join("\\")}]`, "g");
                        textHighlight = v.replace(pattern, "\\$&");
                        const highlightBy = highlightByQuery(textHighlight, item.from_raw_s);
                        const unicodeToChar = MailUtils.unicodeToChar(highlightBy);
                        item.senderName = unicodeToChar;
                      } catch (error) {
                      }
                      item.mailSender = `${item.from_raw_s}<${item.from_s}>`;
                      item.avatar_url = avatarURL;
                      item.totalAttachments = !!item.mail_attachments_file_name_txt ? item.mail_attachments_file_name_txt.length : 0;

                      item.attachmentText = `${item.totalAttachments} ${attachmentText}`;
                      item.title = item.title_s;
                      const folder = this.mailService.flatFolders[item.mail_folder_id_i];
                      item.mailFolder = folder?.name;
                      item.folderPath = folder?.absFolderPath || "/Inbox";
                      item.description = MailUtils.HTMLToPlainText(content).replace("<", "&lt;").replace(">", "&gt;");
                      item.datetime = new DisplayTimePipe(this.translateService).transform(new Date(item.created_dt).getTime(), "MMM DD, YYYY LT") ;
                      item.name = item.title_s;
                      return item;
                    });
                  } else {
                    return of([]);
                  }
                })
              );
          }
          return of([]);
        }))
        .subscribe((v) => {
          console.log("searchControl", v);
          this.topBarService.setSearchItems(v);
          this.appService.createSearchQuery({
            f: ["product", "q"],
            query: {
              name: this.topBarService.searchControl.value
            },
            op: {
              product: "=",
              q: "~"
            },
            v: {
              product: ["mail"],
              q: [this.topBarService.searchControl.value]
            }
          }).subscribe((r: any) => {
            this.topBarService.getSavedSearchItems().pipe(take(1)).subscribe(results => {
              this.topBarService.setSavedSearchItems([r.query, ...results]);
            });
          });
        }, err => {
          this.toastService.showPlainMessage(err);
          this.autoSearchResult = [];
          this.changeDetectorRef.markForCheck();
        });

    this.topBarService.onSelectItem$.pipe(takeUntil(this.isAlive$))
      .subscribe(item => {
          this.searchKeyword = item.originalTitle;
          this.changeDetectorRef.markForCheck();
          console.log("onSelectItem", item);
          if (item.mail_id_i) {
            this.openMail(item.mail_id_i, item.mail_folder_id_i, item.fid_s);
          } else {
            this.search(item);
          }
      });
  }

  setUrlsFromConfig() {
    if (this.config.URLS.serviceDesk) {
      this.serviceDeskURL = this.config.URLS.serviceDesk;
    } else {
      this.serviceDeskURL = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (this.browserLang === "en") {
      if (this.config.URLS.faq) {
        this.faqURL = this.config.URLS.faq;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vncmail-faq";
      }
      if (this.config.URLS.termsOfUseURL) {
        this.termsOfUse = this.config.URLS.termsOfUseURL;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.dataPrivacyURL) {
        this.dataPrivacy = this.config.URLS.dataPrivacyURL;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
      if (this.config.URLS.imprintURL) {
        this.imprintLink = this.config.URLS.imprintURL;
      } else {
        this.imprintLink = "https://vnclagoon.com/about-vnc/legal-information/";
      }
      if (this.config.URLS.userManual) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vncmail/browsermanual/";
      }
    } else {
      if (this.config.URLS.faq_de) {
        this.faqURL = this.config.URLS.faq_de;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vncmail-faq-de";
      }
      if (this.config.URLS.termsOfUseURLde) {
        this.termsOfUse = this.config.URLS.termsOfUseURLde;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/Nutzungsbedingungen/";
      }

      if (this.config.URLS.dataPrivacyURLde) {
        this.dataPrivacy = this.config.URLS.dataPrivacyURLde;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }

      if (this.config.URLS.imprintURLde) {
        this.imprintLink = this.config.URLS.imprintURLde;
      } else {
        this.imprintLink = "https://vnclagoon.com/de/uber-uns/rechtsinformationen/";
      }

      if (this.config.URLS.userManual_de) {
        this.userManual = this.config.URLS.userManual_de;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vncmail/browsermanual/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  updateMailListScrollBroadcast(isHide) {
    if (CommonUtils.isOnIOS) {
      this.mailBroadCaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, isHide);
    }
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  countUnread(res): void {
    const arrayLength = res.length;
    for (let i = 0; i < arrayLength; i++) {
      if (res[i].u) {
        this.siteHeaderMenu[0]["badge"] += res[i].u;
      }
      if (res[i].children) {
        this.countUnread(res[i].children);
      }
    }
  }

  openLegaldialog(): void {
    this.matDialog.open(LegalNoticeDialogComponent, {
      maxWidth: "100%",
      height: "215px",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  searchOnGoogle(): void {
    const url = "https://www.google.com/search?q=" + this.searchKeyword;

    if (this.electronService.isElectron) {
      this.electronService.openExternalUrl(url);
    } else if (typeof device !== "undefined") {
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else {
      window.open(url);
    }
  }

  toggleMobileSearch(): void {
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }
    this.searchMode = !this.searchMode;
    this.clearSearchText();
  }

  search(filters?: any): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    setTimeout(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }, 900);
    if (this.searchFor === "contact") {
      if (!this.searchKeyword) {
        return;
      }
      if (this.currentUrl.startsWith("/contacts/search")) {
        this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
      }
      this.router.navigate(["/contacts/search"], { queryParams: { searchText: this.searchKeyword } });
    } else if (this.searchFor === "briefcase") {
      if (!this.searchKeyword) {
        return;
      }
      if (this.currentUrl.startsWith("/briefcase/search")) {
        this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
      }
      this.router.navigate(["/briefcase/search/" + this.searchKeyword]);
    } else if (this.searchFor === "calendar") {
      if (!this.searchKeyword) {
        return;
      }
      this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
      this.router.navigate(["/calendar"]);
      setTimeout(() => {
        this.mailBroadCaster.broadcast("CALENDAR_SEARCH", {
          search: this.searchKeyword
        });
      }, 100);
    } else {
      this.topBarService.searchControl.patchValue("");
      this.openAdvanceSearch(filters);
      // this.searchKeyword = this.searchKeyword === undefined ? "" : this.searchKeyword;
      // this.store.dispatch(new SetSearchFilters({keyword: this.searchKeyword}));
      // this.router.navigate(["/mail/search"]);
      // this.mailBroadCaster.broadcast(MailConstants.SAVED_SEARCH_ROUTE);
      // this.mailBroadCaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
    }
    this.autoSearchResult = [];
    this.changeDetectorRef.markForCheck();
  }



  openAdvanceSearch(filters?: any) {
    console.log("openAdvanceSearch", filters);
    let options: any = {
      width: "100vw",
      minWidth: "100vw",
      height: "100vh",
    };
    let languages = {};
    let folders = [];
    let contacts = [];
    this.mailBroadCaster.broadcast("FORCE_RESET_SELECTION"); // To fix when the mail is selected but we delete the keyword in input field then the email is also deleted
    this.store.select(getMailFolders).pipe(take(1)).subscribe(res => {
      if (res) {
        folders = res;
      }
    });
    this.store.select(getAllUserContacts).pipe(take(1)).subscribe( res => {
      contacts = _.uniqBy(res.filter(v => !!v.fileAsStr).map(v => {
        return {
          name: v.fileAsStr,
          bare: v.email
        };
      }), "bare");
    });

    this.cc_raw_ss.map(cc=>{
      if (!contacts.some(contact => contact.bare === cc.email)) {
        contacts.push({ name: cc.name, bare: cc.email });
      }
    })

    this.from_raw_ss.map(fr=>{
      if (!contacts.some(contact => contact.bare === fr.email)) {
        contacts.push({ name: fr.name, bare: fr.email });
      }
    })

    this.bcc_raw_ss.map(bcc=>{
      if (!contacts.some(contact => contact.bare === bcc.email)) {
        contacts.push({ name: bcc.name, bare: bcc.email });
      }
    })

    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.LANGUAGE_KEY)
        : localStorage.getItem(MailConstants.LANGUAGE_KEY);
    this.globalSearchService.setLanguage(localLang);
    this.commonService.$isGlobalSearchModalOpen.next(true);
    this.translate.getTranslation(localLang).pipe(take(1)).subscribe(v => {
      languages = v;
      this.globalSearchService.setTranslations(v);
      this.vnclibraryService.setTranslations(v);
      this.globalSearchService.setSearchHelpTranslations(v);
      if (!!filters && filters.filters && filters.filters.q && filters.filters.q.values[0]) {
        this.searchKeyword = filters.filters.q.values[0];
      }
      const dialog = this.matDialog.open(GlobalSearchComponent, Object.assign({
          backdropClass: "vnctalk-advanced-search-backdrop",
          panelClass: "vnctalk-advanced-search-panel",
          disableClose: true,
          data: {
            useOldSearch: false,
            tags: [],
            folders: folders,
            keyword: this.searchKeyword,
            folderMap: this.mailService.flatFolders,
            allContacts: contacts,
            custom_filters: filters?.options?.custom_filters,
            translations: languages,
            userJid: this.currentUser?.email,
            hideChannelTab: true,
            mailOnly: true,
            hideBroadcastTab: true,
            hideBroadcast: true,
            avatarServiceUrl: this.configService.avatarURL,
            onlineJids: []
          },
          autoFocus: true
         }, options));

        dialog.afterOpened().subscribe(v => {
          if (document.querySelectorAll(".vnc-multiple-selection-form") !== null) document.querySelectorAll(".vnc-multiple-selection-form label").forEach((v, i) => {
            if (!(<HTMLElement>document.querySelectorAll(".vnc-multiple-selection-form label")[i]).innerText) {
              (<HTMLElement>document.querySelectorAll(".vnc-multiple-selection-form label")[i]).innerText = this.attachmentTitle;
            }
          });
        });

         dialog.afterClosed().pipe(take(1)).subscribe(v => {
          this.topBarService.closeAutocomplete();
          this.toggleMobileSearch();
          setTimeout(() => {
            if (!!document.querySelector(".vnc-search-bar__input")) {
              (<HTMLElement> document.querySelector(".vnc-search-bar__input")).blur();
            }
          }, 100);
          this.topBarService.clearSearchText();
          this.commonService.$isGlobalSearchModalOpen.next(false);
          if (v && v.mail) {
            // console.log("header.component openMailFromSearch1 ", v.mail);
            this.openMail(v.mail.mail_id_i, v.mail.mail_folder_id_i, v.mail.fid_s);
          }
         });

      });

  }

  getFolderByFid(fid: any) {
    const f1 = this.mailService.flatFolders[fid];
    // console.log("openMailFromSearch testing folder1: ", f1, fid);
    if (!!f1) {
      return f1;
    }
    if (fid.indexOf(":") === -1) {
      return;
    }
    const zid = fid.split(":")[0];
    const rid = fid.split(":")[1];
    // console.log("openMailFromSearch zid, rid: ", zid, rid);

    const testFolder = this.mailService.flatArrayFolders.find(f => (f.rid === +rid && f.zid === zid));
    return testFolder;
  }

  openMail(mailId: any, folderId: any, fid?: any) {
    // console.log("header.component openMailFromSearch ", mailId, folderId);

    const folder = !!fid ? this.getFolderByFid(fid) : this.mailService.flatFolders[folderId];
    const zid = !!fid ? fid.split(":")[0] : "";

    const folderPath = MailUtils.getFolderPath(folder.id);
    const parentFolder = MailUtils.getParentById(this.mailService.flatFolders, folder.id);

    this.store.select(getExpandMailFolders).pipe(take(1)).subscribe(res => {
      if (res) {
          const expandMailFolders = res.split(",");
          if (parentFolder && expandMailFolders.indexOf(`${parentFolder.id}:true`) === -1) {
            expandMailFolders.push(`${parentFolder.id}:true`);
            this.mailBroadCaster.broadcast(MailConstants.EXPAND_FOLDER, {selectedFolder: parentFolder});
          }
          if (expandMailFolders.indexOf(`${folderId}:true`) === -1) {
            expandMailFolders.push(`${folderId}:true`);
          }
          this.store.dispatch(new SetExpandMailFolders(expandMailFolders.toString()));

      }
    });

    if (folder) {
      if (!!folder.owner && folder.zid) {
        mailId = folder.zid + ":" + mailId;
      }
      this.store.dispatch(new SetSelectedMailFolder(folder));
      this.expandAllParentFolders(folderId, parentFolder);
    }
    this.mailBroadCaster.broadcast("FORCE_RESET_SELECTION");
    // console.log("header.component openMailFromSearch ", mailId, folderPath, folderId, folder);
    const mid = (folder.id.indexOf(":") > -1) ? zid + ":" + mailId : mailId;
    if (+folderId === 6) {
      this.router.navigate([`${folderPath}/detail/draft/m/${mid}`]);
    } else {
      this.router.navigate([`${folderPath}/detail/m/${mid}`]);
    }
    setTimeout(() => {
      this.mailBroadCaster.broadcast("SCROLLTORESULTID", mailId);
    }, 400);
  }

  expandAllParentFolders(folderId, parentFolder) {
    const folder = this.mailService.flatFolders[folderId];
    if (folder) {
      if (folder && folder.l && parentFolder && parentFolder.id !== folder.l && this.mailService.flatFolders[folder.l]) {
        this.mailBroadCaster.broadcast(MailConstants.EXPAND_FOLDER, {selectedFolder: this.mailService.flatFolders[folder.l], folderId: folderId });
        this.expandAllParentFolders(folder.l, parentFolder);
      }
    }
  }

  searchOnKeyUp() {
    if (this.searchFor === "mail") {
      this.searchKeyDown.next(true);
    }
  }

  saveSearch() {
    this.mailBroadCaster.broadcast(MailConstants.BROADCAST_SAVE_SEARCH, true);
  }

  openProfileDialog() {
    if (this.mailService.showOfflineMessage()) {
      return;
    }
    if (this.isMobileScreen) {
      this.mailService.getLoggedInUserContactInfo(this.currentUser.email).pipe(take(1)).subscribe(res => {
        console.log("[getContactInfo]::", res);
        let contact = res;
        this.matDialog.open(ProfileDetailDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            data: { email: this.currentUser.email, currentUser: this.currentUser, contact: contact, isLoggedInUser: true },
            panelClass: "user_contact_detail_dialog"
        });
      });
    } else {
      this.store.dispatch(new SetActiveProfile(this.currentUser.email));
      this.store.dispatch(new SetRightSidebarStatus(true));
    }

  }

  routeToSystemFolder(title) {
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
    if (this.router.url === "/mail" + title) {
      this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
    }
    this.router.navigate(["/mail" + title]);
  }

  openSubFolders() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      data: { rootFolder: null },
      panelClass: "mail__dialog",
    };
    this.matDialog.open(SubFolderComponent, dialogArgs);
    this.changeDetectorRef.markForCheck();
  }

  openBriefcase() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.router.navigate(["/briefcase"]);
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
  }

  openContact(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.router.navigate(["/contacts/all"]);
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
  }

  openTags() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      panelClass: "mail__dialog",
    };
    this.matDialog.open(MobileTagsComponent, dialogArgs);
    this.changeDetectorRef.markForCheck();
  }

  openPreferenceDialog() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }
    this.router.navigate(["/preferences/", "prefOptions"]);
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
  }

  clearSearchText(): void {
    this.searchKeyword = "";
  }

  toggleRefineForm() {
    this.headerService.openRefineSearchDialog.next(true);
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      height: "100%",
      panelClass: "refine-search-dialog",
    };
    this.matDialog.open(RefineSearchDialogComponent, dialogArgs);
  }

  toggleAdvancedSearchForm() {
    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      height: "100%",
      panelClass: "advanced-search-dialog",
      data: {keyword: this.searchKeyword, isHideContactSearchSelectionOption: this.isHideContactSearchSelectionOption}
    };
    const dialogRef = this.matDialog.open(AdvancedSearchDialogComponent, dialogArgs);
    dialogRef.afterClosed().pipe(take(1)).subscribe(data => {
      if (data && data.keyword) {
        this.searchKeyword = data.keyword;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }


  switchApps($event) {
    if (window.outerWidth > 767) {
      $event.switch = true;
    } else {
      this.showUserMenu = true;
    }
  }

  switchApplication(event): void {
    if (event && event.switch) {
      if (this.isMobileScreen) {
        this.showUserMenu = true;
        this.updateMailListScrollBroadcast(true);
      } else {
        this.switchApp = this.switchApp ? false : true;
      }
      this.changeDetectorRef.markForCheck();
    } else {
      this.switchApp = false;
    }
  }

  // TODO: move to new header
  siwtchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
      this.updateMailListScrollBroadcast(true);
    } else {
      this.switchApp = this.switchApp ? false : true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppAwitcher() {
    this.switchApp = false;
    this.changeDetectorRef.markForCheck();
  }

  updateEmailExpanderPanel() {
    this.emailOptionExpanded = this.emailOptionExpanded ? false : true;
  }

  selectedOption(event: MatAutocompleteSelectedEvent): void {
    this.searchKeyword = event.option.value as string;
  }

  logout(): void {
    this.showUserMenu = false;
    let options: any = {
      width: "328px",
      height: "280px",
    };
    setTimeout(() => {
      this.ngZone.run(async () => {
        const { LogoutConfirmationComponent } = await import(
          "../shared/components/logout-confirmation/logout-confirmation.component");
        this.matDialog.open(LogoutConfirmationComponent, Object.assign({
          backdropClass: "vncMail-form-backdrop",
          panelClass: ["vncmail-form-panel-style", "mail-logout-mobile-view"],
          disableClose: true,
          data: {
          },
          autoFocus: true
         }, options));
      });

    }, 500);
  }

  navigatorTo(url: string): void {
    if (this.mailService.showOfflineMessage()) {
      return;
    }

    this.currentUrl = this.router.routerState.snapshot.url;
    this.currentUrl = this.router.routerState.snapshot.url;
    if (this.currentUrl.startsWith("/mail")) {
      localStorage.setItem("lastVisitedTab", "/mail");
    } else if (this.currentUrl.startsWith("/contacts")) {
      localStorage.setItem("lastVisitedTab", "/contacts");
    } else if (this.currentUrl.startsWith("/briefcase")) {
      localStorage.setItem("lastVisitedTab", "/briefcase");
    } else if (this.currentUrl.startsWith("/calendar")) {
      localStorage.setItem("lastVisitedTab", "/calendar");
    }
    this.router.navigate([url]);
  }

  shortcutDialog() {
    this.matDialog.open(ShortcutDialogComponent, {
      width: "90%",
      maxWidth: "100vw",
      autoFocus: false
    });
  }

  registerShortcuts(): void {
    this._hotkeysService.register([
      {
        combo: "ctrl+q",
        handler: () => {
          this.shortcutDialog();
          return false;
        },
        description: "Print selected mail"
      }
    ]);
  }

  generalSettings(): void {
    this.matDialog.open(GeneralSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  changelogDialog(): void {
    this.matDialog.open(ChangelogDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  aboutDialog(): void {
    this.matDialog.open(AboutDialogComponent, {
      maxWidth: "100%",
      width: "360px",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  serviceDeskDialog(): void {
    this.matDialog.open(ServicedeskDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  helpFaqDialog(): void {
    this.matDialog.open(HelpFaqDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  mobileAboutDialog(): void {
    this.matDialog.open(MobileAboutDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  openAppSwitcherDialog(): void {
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail__dialog"
    });
  }

  changePassword(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.mailService.getChangePasswordData().then( res => {
      const changePasswordType =  res.changePasswordType;
      if (changePasswordType === MailConstants.ZIMBRA_TYPE) {
        const sectionAttributes = JSON.parse(res.sectionAttributes);
        if (sectionAttributes.attrs._attrs.zimbraFeatureChangePasswordEnabled === "TRUE") {
          const changePasswordURL = sectionAttributes.changePasswordURL !== undefined ? sectionAttributes.changePasswordURL : "";
          if (changePasswordURL === "") {
            if (CommonUtils.isOnMobileDevice()) {
              this.openMobileChangePasswordDialog(changePasswordType , changePasswordURL);
            } else {
              this.openChangePasswordDialog(changePasswordType , changePasswordURL);
            }
          } else {
            window.open(changePasswordURL);
          }
        } else {
          this.toastService.show(MailConstants.PASSWORD_CHANGE_FEATURE_NOT_ENABLE);
        }
      }
    });
  }

  openChangePasswordDialog(type: string , changePasswordUrl: string ): void {
    const dialogArgs = {
      width: "325px",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "desktop_change_password"
    };
    this.matDialog.open(DesktopChangePasswordComponent, dialogArgs);
  }

  openMobileChangePasswordDialog(type: string , changePasswordUrl: string): void {
    const dialogArgs = {
      maxWidth: "100vw",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "mobile_change_password"
    };
    this.matDialog.open(MobileChangePasswordComponent, dialogArgs);
  }

  routeToEmailTemplateFolder() {
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    if (this.router.url === "/mail/folder/" + this.emailTemplateFolder.id) {
      this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
    }
    this.router.navigate([ "/mail/folder/" + this.emailTemplateFolder.id]);
  }

  searchFocus() {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
  }

  searchFocusOut() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  setLogoAndText(): void {
    if (localStorage.getItem(MailConstants.THEME) !== null) {
      if (localStorage.getItem(MailConstants.THEME) === MailConstants.DFB) {
        this.setHeaderBrandName();
        this.appLogo = CommonUtils.getFullUrl("/assets/img/DFBnet_Logo_net.png");
        this.appLogoDesktop = CommonUtils.getFullUrl("/assets/img/DFBnet_Logo_net_desk.png");
        this.isGreenTheme = true;
      } else {
        this.setHeaderBrandName();
        this.appLogo = CommonUtils.getFullUrl("/assets/img/DFBnet_Logo_net.png");
        this.appLogoDesktop = CommonUtils.getFullUrl("/assets/img/DFBnet_Logo_net_desk.png");
        this.isBlueTheme = true;
      }
    }
  }

  getSendASAndBehalfOf(): void {
    const fromAddress: any[] = [];
    this.preferenceService.discoverRightsRequest().pipe(take(1)).subscribe( res => {
      if (res.DiscoverRightsResponse && res.DiscoverRightsResponse[0].targets && isArray(res.DiscoverRightsResponse[0].targets)) {
        const discoverRights = res.DiscoverRightsResponse[0].targets;
        discoverRights.map( disCover => {
          if (disCover.right === "sendOnBehalfOf") {
            if (disCover.target && isArray(disCover.target)) {
              const behalf = disCover.target;
              behalf.map( b => {
                 fromAddress.push({
                  value: b.email[0].addr,
                  displayValue: "on behalf of " + b.email[0].addr
                });
              });
            }
          } else if (disCover.right === "sendAs") {
            if (disCover.target && isArray(disCover.target)) {
              const sendAs = disCover.target;
              sendAs.map( s => {
                fromAddress.push({
                  value: s.email[0].addr,
                  displayValue: s.email[0].addr
                });
              });
            }
          }
        });

        this.preferenceService.sendAsAndBehalf = fromAddress;
      }
    });
  }

  getPersonas(): void {
    this.mailService.getAttributes("idents").pipe(take(1)).subscribe(res => {
      console.log("Identity (Persona) :: ", res.identities);
      if (res.identities && res.identities.identity && isArray(res.identities.identity)) {
        this.preferenceService.personas = res.identities.identity;
      }
    });
  }

  getDataSource(): void {
    this.preferenceService.getDataSources().subscribe(res => {
      this.preferenceService.dataSource$ = res;
    });
  }

  showSearchPopover(): void {
    if (this.showAdvancedSearchPopover) {
      this.showAdvancedSearchPopover = false;
    } else {
      this.showAdvancedSearchPopover = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  startResync() {
    const oldLastMessagesTimestamp = localStorage.getItem("lastMessagesTimestamp");
    if (!oldLastMessagesTimestamp) {
      const oneWeekAgo = Date.now() - 604800000;
      const oneMonthAgo = Date.now() - 2592000000;
      localStorage.setItem("lastMessageTimestamp", "" + oneMonthAgo);
      this.mailBroadCaster.broadcast("STARTRESYNC");
    }
  }

  private getDomainSpecificUsers(): void {
    this.store.select(getDomainSpecificLogo).pipe(take(1)).subscribe(res => {
      if (environment.theme === MailConstants.DFB) {
        if (!!this.currentUser && this.currentUser !== null && res.length > 0) {
          const domain = this.currentUser.email.split("@")[1];
          res.map( item => {
            if (item.domain === domain) {
              this.domainSpecificUserLogo = item.logo;
              this.domainSpecificLogoAvailable = true;
            }
          });
          console.log("[domainSpecificLogoAvailable] : ", this.domainSpecificLogoAvailable);
          console.log("[domainSpecificUserLogo] : ", this.domainSpecificUserLogo);
          this.changeDetectorRef.markForCheck();
        }
      }
    });
  }
  setIncludeSharedItems() {
    if (this.includeSharedItems) {
      this.includeSharedItems = false;
    } else {
      this.includeSharedItems = true;
    }
    this.store.dispatch(new SetIncludeShared(this.includeSharedItems));
    localStorage.setItem(MailConstants.INCLUDE_SHARED_ITEM, this.includeSharedItems.toString());
    console.log("[setIncludeSharedItems]", this.includeSharedItems);
  }

  setSearchFor(value) {
    console.log("[setSearchFor]", value);
    this.store.dispatch(new SetSearchFor(value));
  }

  setUserQuotaInfo(usedQuota: number): void {
    if (!!localStorage.sectionAttributes && localStorage.sectionAttributes !== null) {
      const sectionAttributes = JSON.parse(localStorage.sectionAttributes);
      let quota = 0;
      let translationText = "";
      if (sectionAttributes && sectionAttributes.attrs && sectionAttributes.attrs._attrs
        && sectionAttributes.attrs._attrs.zimbraMailQuota) {
        quota = parseInt(sectionAttributes.attrs._attrs.zimbraMailQuota, 10);
      }
      const size = this.formatFileSizePipe.transform(usedQuota, false).toUpperCase();
      const data: any = {
        quota: quota,
        usedQuota: usedQuota,
        size: size
      };
      if (data.quota) {
        data.limit = this.formatFileSizePipe.transform(data.quota, false).toUpperCase();
        data.percent = Math.min(Math.round((data.usedQuota / data.quota) * 100), 100);
        this.configService.setUsedQuotaPercent(data.percent);
        this.translateService.get(
          "LIMITED_QUOTA_MSG",
          { usedQuota: data.size, percentage: data.percent, mailQuota: data.limit }
        ).pipe(take(1)).subscribe((text: string) => {
          translationText = text;
          data.desc = translationText;
          this.quotaMsg = data.desc;
          this.quotaPercent = data.percent.toString();
          this.changeDetectorRef.markForCheck();
        });
      } else {
        this.translateService.get(
          "UNLIMITED_QUOTA_MSG",
          { usedQuota: data.size })
          .pipe(take(1)).subscribe((text: string) => {
            translationText = text;
            data.desc = translationText;
            this.quotaMsg = data.desc;
            this.changeDetectorRef.markForCheck();
        });
      }
      // console.log("[UserMailQuota] : ", data);
      this.changeDetectorRef.markForCheck();
    }
  }

  showQuotaInformation(): void {
    this.toastService.showPlainMessage(this.quotaMsg.replace("<br/>", ""), 3000);
  }

  openCalendar(): void {
    this.router.navigate(["/calendar"]);
    this.showUserMenu = false;
    this.updateMailListScrollBroadcast(false);
    this.changeDetectorRef.markForCheck();
  }

  searchFromText(ev: any): void {
    if (ev.key === "Enter") {
      this.search();
     }
  }

  getFirstLastCharacters(user) {
    let characters = user.firstName ? user.firstName.charAt(0) : "";
    characters = characters + (user.lastName ? user.lastName.charAt(0) : "");
    if (characters === "") {
      characters = user.email.charAt(0);
    }
    return characters;
  }

  changeAppearance(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.matDialog.open(AppearanceDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "mail-apprearance-dialog"
    });
  }

  setHeaderBrandName(): void {
    const prefixBold = this.config.get("prefixBold");
    const suffixNormal = this.config.get("suffixNormal");
    let prefixValue: string = "";
    let suffixValue: string = "";
    let setLogoData: string = "";
    if (!!prefixBold && prefixBold !== null) {
      prefixValue = prefixBold;
    }
    if (!!suffixNormal && suffixNormal !== null) {
      suffixValue = suffixNormal;
    }
    // console.log("[setHeaderBrandName][SuffixValue] :", suffixValue);
    // console.log("[setHeaderBrandName][prefixValue] :", prefixValue);
    if (prefixValue === "" && suffixValue === "") {
      if (environment.theme === MailConstants.DFB) {
        setLogoData = "<span class=\"vnc\">DFB</span><span class=\"mail\">mail</span>";
      } else {
        this.srcLogo = CommonUtils.getFullUrl("/assets/images/logo-mail.svg");
        setLogoData = "<span class=\"vnc\">VNC</span><span class=\"mail\">mail</span>";
      }
    } else if (`${prefixValue}${suffixValue}`.toLocaleLowerCase() === "vncmail") {
      this.srcLogo = CommonUtils.getFullUrl("/assets/images/logo-mail.svg");
    } else {
      setLogoData = "<span class=\"vnc\">" + prefixValue + "</span><span class=\"mail\">" + suffixValue + "</span>";
    }
    if (this.calendarOnly) {
      this.srcLogo = CommonUtils.getFullUrl("/assets/images/logo-calendar.svg");
      setLogoData = "<span class=\"vnc\">VNC</span><span class=\"mail\">calendar</span>";
    }
    this.textLogo = setLogoData;
    this.changeDetectorRef.markForCheck();
  }

  selectSearchItem(ev: any, item: string): void {
    this.searchKeyword = item;
    this.changeDetectorRef.markForCheck();
    this.search();
  }

  onCloseAutocomplete() {
    this.topBarService.clearSearchText();
  }

  toggleSidebar() {
    if (!this.rightSidebarExpanded) {
      this.store.dispatch(new SetActiveProfile(this.currentUser.email));
    }
    this.store.dispatch(new SetRightSidebarStatus(!this.rightSidebarExpanded));
  }

  openSearchOptionsMenu() {
    this.searchOptionsMenuTrigger.openMenu();

  }
  openAdvancedSearchMenu() {
    this.advancedSearchMenuTrigger.openMenu();
  }

  setView(view: CalendarView) {
    this.mailBroadCaster.broadcast("setView", view);
  }

}
