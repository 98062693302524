
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { filter, take, takeUntil, Subject } from "rxjs";
import { Appointment } from "src/app/common/models/appoinment.model";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { CommonService } from "src/app/services/ common.service.";
import { DatabaseService } from "src/app/services/db/database.service";
import { UserProfile } from "src/app/shared/models";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { getCalendarFolders, getUserProfile } from "src/app/reducers";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { isArray } from "util";
import { CalendarFolder, KeyValue } from "src/app/common/models/calendar.model";
import { CalenderUtils } from "../utils/calender-utils";
import { CalendarRepository } from "../repositories/calendar.repository";

@Injectable()
export class SharedCalendarService implements OnDestroy {
    currentUser: UserProfile;
    private isAlive$ = new Subject<boolean>();
    calendarFolders: CalendarFolder[] = [];
    alaramList: KeyValue[] = [];
    alarmReminder: string = "0";
    alarmReminderOption: any;

    constructor(
        private commonService: CommonService,
        private router: Router,
        private broadcaster: MailBroadcaster,
        private calendarRepository: CalendarRepository,
        private toastService: ToastService,
        private translateService: TranslateService,
        private databaseService: DatabaseService,
        private store: Store<CalendarState>,
    ) {
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            let tempProfile = null;
            if (!!res) {
                tempProfile = res;
                tempProfile.email = this.getSingleEmail(res.email);
                this.currentUser = tempProfile;
            } else {
                const contactUser = localStorage.getItem("profileUser");
                if (!!contactUser) {
                    this.currentUser = MailUtils.parseUserProfile(contactUser);
                }
            }
        });
        this.store.select(getCalendarFolders).pipe(take(1)).subscribe(res => {
            if (!!res && res !== null) {
                this.calendarFolders = res;
                this.calendarFolders = this.calendarFolders.filter(f => f.id !== "3");
            }
        });
        this.alaramList = this.calendarRepository.reminderOptions();

    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    getSingleEmail(emails) {
        if (isArray(emails)) {
            return emails[0];
        } else if (emails) {
            return emails;
        }
        return null;
    }

    public removefirebasenotificationOnDelete(appointmentId: string): void {
        if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
            console.log("[removeFirebaseNotificationOnDelete][Appointment Id] ", appointmentId);
            window.FirebasePlugin.clearNotificationByAppointmentId(appointmentId, () => {
                console.log("[removeFirebaseNotificationOnDelete] success");
            }, error => {
                console.error("[removeFirebaseNotificationOnDelete] error", error);
            });
        }
    }

    public getModifyApptBody(appt: Appointment): any {
        const body: any = {
            id: appt.id,
            subject: appt.name,
            uid: appt.uid,
            emailInfo: this.getEmailInformation(),
            folderId: appt.ciFolder,
            comp: "0",
            ms: appt.ms,
            rev: appt.rev,
            mp: [{
                ct: "text/html",
                content: appt.descHTML
            }],
            inviteInfo: [{
                allDay: appt.allDay ? "1" : "0",
                at: appt.at,
                class: appt.class,
                e: {
                    tz: appt.endDateData[0].tz,
                    d: appt.endDateData[0].d
                },
                s: {
                    tz: appt.startDateData[0].tz,
                    d: appt.startDateData[0].d
                },
                or: {
                    a: appt.or.a
                },
                loc: appt.loc,
                status: "CONF",
                transp: appt.transp,
                fb: appt.fb,
                name: appt.name
            }]
        };
        return body;
    }

    getEmailInformation(): any {
        const emailInfo: any[] = [];
        emailInfo.push({ a: this.currentUser.email, t: "f" });
        return emailInfo;
    }


    openComposeForReplyAll(body: any, event: any, isOnline: boolean): void {
        if (isOnline) {
            this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
                const appt = CommonUtils.mapAppointmentFromMsg(res.m[0]);
                let description: string = "";
                let translation: any = {};

                const toFields: any[] = [];
                const ccFields: any[] = [];
                const attndee: any[] = [];
                if (event.isOrganizer) {
                    appt.at.map(filed => {
                        if (filed.role === "REQ") {
                            toFields.push({
                                a: filed.a,
                                d: filed.a,
                                t: "t"
                            });
                        }
                        if (filed.role === "OPT") {
                            ccFields.push({
                                a: filed.a,
                                d: filed.a,
                                t: "c"
                            });
                        }
                        attndee.push(filed.a);
                    });
                } else {
                    if (appt.or && appt.or.a) {
                        toFields.push({
                            a: appt.or.a,
                            d: appt.or.a,
                            t: "t"
                        });
                    }
                    appt.at.map(filed => {
                        if (filed.role === "REQ") {
                            if (filed.a !== this.currentUser.email) {
                                toFields.push({
                                    a: filed.a,
                                    d: filed.a,
                                    t: "t"
                                });
                            }
                        }
                        if (filed.role === "OPT") {
                            if (filed.a !== this.currentUser.email) {
                                ccFields.push({
                                    a: filed.a,
                                    d: filed.a,
                                    t: "c"
                                });
                            }
                        }
                        attndee.push(filed.a);
                    });
                }

                if (description === "") {
                    this.translateService.get([
                        "CALENDARS.ORIGINAL_APPT_LBL", "CALENDARS.ORGANIZER_LBL", "CALENDARS.FOLLOWING_NEW_MEETING_REQUEST",
                        "CALENDARS.TIME_LBL", "CALENDARS.INVITEES_LBL", "CALENDARS.SUBJECT_LBL"]
                    ).pipe(take(1)).subscribe(resp => {
                        translation = resp;
                        const start = moment(event.start).format("LLLL");
                        const end = moment(event.end).format("LTS");
                        description += "<b>" + translation["CALENDARS.SUBJECT_LBL"] + "</b>" + ":" + event.title + "<br/>" +
                            "<b>" + translation["CALENDARS.ORGANIZER_LBL"] + "</b>" + ": " + appt.or.a + "<br/>" +
                            "<b>" + translation["CALENDARS.TIME_LBL"] + "</b>" + ": " + start + " - " + end + " <br/>" +
                            "<b>" + translation["CALENDARS.INVITEES_LBL"] + "</b>" + ": " + attndee.toString() +
                            "<br/><br/>*~*~*~*~*~*~*~*~*~*";
                    });
                }

                const id = appt.id;
                const subject = appt.name;
                this.router.navigate(["/mail/compose/replyall"]);
                setTimeout(() => {
                    this.broadcaster.broadcast("COMPOSE_CALENDAR_REPLY_ALL", {
                        id: id,
                        desc: description,
                        toFileds: toFields,
                        ccFields: ccFields,
                        subject: subject
                    });
                }, 10);
            });
        } else {
            const appId = (!!event.invId) ? event.invId : event.id;
            this.databaseService.getAppointmentsById(appId).pipe(take(1)).subscribe((res: any) => {
                if (!res || !res.isMapped) {
                    this.toastService.show("FORBIDDEN");
                    return;
                }
                let description: string = "";
                let translation: any = {};

                const toFields: any[] = [];
                const ccFields: any[] = [];
                const attndee: any[] = [];
                if (event.isOrganizer) {
                    res.at.map(filed => {
                        if (filed.role === "REQ") {
                            toFields.push({
                                a: filed.a,
                                d: filed.a,
                                t: "t"
                            });
                        }
                        if (filed.role === "OPT") {
                            ccFields.push({
                                a: filed.a,
                                d: filed.a,
                                t: "c"
                            });
                        }
                        attndee.push(filed.a);
                    });
                } else {
                    if (res.or && res.or.a) {
                        toFields.push({
                            a: res.or.a,
                            d: res.or.a,
                            t: "t"
                        });
                    }
                    res.at.map(filed => {
                        if (filed.role === "REQ") {
                            if (filed.a !== this.currentUser.email) {
                                toFields.push({
                                    a: filed.a,
                                    d: filed.a,
                                    t: "t"
                                });
                            }
                        }
                        if (filed.role === "OPT") {
                            if (filed.a !== this.currentUser.email) {
                                ccFields.push({
                                    a: filed.a,
                                    d: filed.a,
                                    t: "c"
                                });
                            }
                        }
                        attndee.push(filed.a);
                    });
                }

                if (description === "") {
                    this.translateService.get([
                        "CALENDARS.ORIGINAL_APPT_LBL", "CALENDARS.ORGANIZER_LBL", "CALENDARS.FOLLOWING_NEW_MEETING_REQUEST",
                        "CALENDARS.TIME_LBL", "CALENDARS.INVITEES_LBL", "CALENDARS.SUBJECT_LBL"]
                    ).pipe(take(1)).subscribe(resp => {
                        translation = resp;
                        const start = moment(event.start).format("LLLL");
                        const end = moment(event.end).format("LTS");
                        description += "<b>" + translation["CALENDARS.SUBJECT_LBL"] + "</b>" + ":" + event.title + "<br/>" +
                            "<b>" + translation["CALENDARS.ORGANIZER_LBL"] + "</b>" + ": " + res.or.a + "<br/>" +
                            "<b>" + translation["CALENDARS.TIME_LBL"] + "</b>" + ": " + start + " - " + end + " <br/>" +
                            "<b>" + translation["CALENDARS.INVITEES_LBL"] + "</b>" + ": " + attndee.toString() +
                            "<br/><br/>*~*~*~*~*~*~*~*~*~*";
                    });
                }

                const id = res.id;
                const subject = res.name;
                this.router.navigate(["/mail/compose/replyall"]);
                setTimeout(() => {
                    this.broadcaster.broadcast("COMPOSE_CALENDAR_REPLY_ALL", {
                        id: id,
                        desc: description,
                        toFileds: toFields,
                        ccFields: ccFields,
                        subject: subject
                    });
                }, 10);
            }, error => {
                console.error(`[ContextMenuComponent][databaseService][getAppointmentsById]`, error);
            });
        }
    }

    getFolderById(folderId: string): CalendarFolder {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let calendarFolder: CalendarFolder;
        if (!!folderId) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            calendarFolder = f;
                        }
                    } else {
                        if (f.id === folderId) {
                          calendarFolder = f;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                      calendarFolder = f;
                    }
                }
            });
        }
        return calendarFolder;
      }

      getFolderNameById(folderId: string): string {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let folderName: string = "";
        if (!!folderId) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            folderName = f.name;
                        }
                    } else {
                        if (f.id === folderId) {
                            folderName = f.name;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                        folderName = f.name;
                    }
                }
            });
        }
        return folderName;
    }

    getFolderColorById(folderId: string): string {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let folderColor: string = "#000099";
        if (!!folderId && !!fld) {
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            folderColor = f.folderColor;
                        }
                    } else {
                        if (f.id === folderId) {
                            folderColor = f.folderColor;
                        }
                    }
                } else {
                    if (f.id === folderId) {
                        folderColor = f.folderColor;
                    }
                }
            });
        }
        return folderColor;
    }

    isReadOnlyFolder(folderId: string): boolean {
        const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
        let readOnly: boolean = false;
        if (!!folderId && !!fld) {
            const folder = fld.filter(f => !!f).filter(f => f.id === folderId)[0];
            fld.map( f => {
                if (folderId.toString().indexOf(":") !== -1) {
                    const zid = folderId.split(":")[0];
                    const rid = folderId.split(":")[1];
                    if (!!f.rid && f.rid) {
                        if (f.zid === zid && f.rid.toString() === rid) {
                            if (f.perm && (f.perm === "r" || f.perm === "rp")) {
                                readOnly = true;
                            }
                        }
                    } else if (f.id === folderId) {
                        if (f.perm && (f.perm === "r" || f.perm === "rp")) {
                            readOnly = true;
                        }
                    }
                }
                if (f.id === folderId && f.url) {
                    readOnly = true;
                }
            });
            return readOnly;
        }
    }

    setAlarmToCompose(appointment: Appointment): void {
        const alaramOptions = this.alaramList;
        this.alarmReminder = "0";
        if (appointment.alarmData) {
            this.alarmReminder = this.getAlarmUnit(appointment.alarmData[0]);
        }
        this.alarmReminder = !!this.alarmReminder ? this.alarmReminder : "-1";
        this.alarmReminder = this.alarmReminder.toString();
        this.alarmReminderOption = this.switchAlarm(this.alarmReminder, alaramOptions, this.alarmReminderOption);
    }

    switchAlarm(reminder,alaramOptions,alReminderOp) {
        switch (reminder) {
            case "0":
                alReminderOp = alaramOptions[0];
                break;
            case "-1":
                alReminderOp = alaramOptions[1];
                break;
            case "1":
                alReminderOp = alaramOptions[2];
                break;
            case "5":
                alReminderOp = alaramOptions[3];
                break;
            case "10":
                alReminderOp = alaramOptions[4];
                break;
            case "15":
                alReminderOp = alaramOptions[5];
                break;
            case "30":
                alReminderOp = alaramOptions[6];
                break;
            case "45":
                alReminderOp = alaramOptions[7];
                break;
            case "60":
                alReminderOp = alaramOptions[8];
                break;
            case "120":
                alReminderOp = alaramOptions[9];
                break;
            case "180":
                alReminderOp = alaramOptions[10];
                break;
            case "240":
                alReminderOp = alaramOptions[11];
                break;
            case "300":
                alReminderOp = alaramOptions[12];
                break;
            case "1080":
                alReminderOp = alaramOptions[13];
                break;
            case "1440":
                alReminderOp = alaramOptions[14];
                break;
            case "2880":
                alReminderOp = alaramOptions[15];
                break;
            case "4320":
                alReminderOp = alaramOptions[16];
                break;
            case "5760":
                alReminderOp = alaramOptions[17];
                break;
            case "10080":
                alReminderOp = alaramOptions[18];
                break;
            case "20160":
                alReminderOp = alaramOptions[19];
                break;
        }
        return alReminderOp;
    }

    getAlarmUnit(tmp): string {
        if (!tmp) { return; }
        let m, h, d, w;
        const trigger = tmp.trigger;
        let _reminderMinutes;
        const rel = (trigger && (trigger.length > 0)) ? trigger[0].rel : null;
        m = (rel && (rel.length > 0)) ? rel[0].m : null;
        d = (rel && (rel.length > 0)) ? rel[0].d : null;
        h = (rel && (rel.length > 0)) ? rel[0].h : null;
        w = (rel && (rel.length > 0)) ? rel[0].w : null;
        _reminderMinutes = 0;
        if (tmp.action === "DISPLAY") {
            if (m !== null) {
                _reminderMinutes = m;
            }
            if (h !== null && !!h) {
                h = parseInt(h, 10);
                _reminderMinutes = h * 60;
            }
            if (d !== null && !!d) {
                d = parseInt(d, 10);
                _reminderMinutes = d * 24 * 60;
            }
            if (w !== null && !!w) {
                w = parseInt(w, 10);
                _reminderMinutes = w * 7 * 24 * 60;
            }
        }
        return _reminderMinutes;
    }

    deleteDefaultAppointment(data: any): void {
        const ev = data.event;
        if (!!ev.isRepeatAppt) {
            if (data.ridZ) {
                this.broadcaster.broadcast("DELETE_SERIES_APPOINTMENT_FROM_PREVIEW", {
                    event: ev
                });
            } else {
                this.broadcaster.broadcast("DELETE_INSTANCE_APPOINTMENT_FROM_PREVIEW", {
                    event: ev
                });
            }
        } else {
            this.broadcaster.broadcast("DELETE_APPOINTMENT_FROM_PREVIEW", {
                event: ev
            });
        }
    }

    
}
